import { API_HOST } from '../_constant/urlconstants';
import { getCurrentSession } from './getCurrectUserToken';
import axios from "axios";

const request = async ({ url, method, data = {} }) => {
  let header = await getCurrentSession();
  const axiosInstance = axios.create({
    baseURL: API_HOST,
    headers: header
  });

  try {

    let response = await axiosInstance.request({
      url,
      method,
      data
    });


    if (response && response.data) {
      return response.data;
    }
  } catch (e) {

    if (e && e.response && e.response.data && e.response.data.errorCode == "403") {
      alert("You are not authorized to access this screen.");
      window.location.assign("/");
      window.location.reload();
    }
  }
  return null
}

export default request;