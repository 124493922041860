import CryptoJS from 'crypto-js';

const SecretKeyPolicy = 'KISANGATWATPOLICY189';

export const encryptUsingAES256WithCBC = (data) => {
  let _key = CryptoJS.enc.Utf8.parse(SecretKeyPolicy);
  let _iv = CryptoJS.enc.Utf8.parse(SecretKeyPolicy);
  let encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    _key,
    {
      keySize: 128 / 8,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  return encrypted;
};

export const decryptUsingAES256WithCBC = (data) => {
  let _key = CryptoJS.enc.Utf8.parse(SecretKeyPolicy);
  let _iv = CryptoJS.enc.Utf8.parse(SecretKeyPolicy);

  let decrypted = CryptoJS.AES.decrypt(
    data,
    _key,
    {
      keySize: 128 / 8,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  ).toString(CryptoJS.enc.Utf8);

  return JSON.parse(decrypted);
};

export const encryptUsingAES256 = (data) => {
  let _key = CryptoJS.enc.Utf8.parse(SecretKeyPolicy);
  let _iv = CryptoJS.enc.Utf8.parse(SecretKeyPolicy);
  let encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    _key,
    {
      keySize: 256 / 8,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
    );

  return encrypted;
};

export const decryptUsingAES256 = (data) => {
  let _key = CryptoJS.enc.Utf8.parse(SecretKeyPolicy);
  let _iv = CryptoJS.enc.Utf8.parse(SecretKeyPolicy);
  let decrypted = CryptoJS.AES.decrypt(
    data,
    _key,
    {
      keySize: 256 / 8,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  ).toString(CryptoJS.enc.Utf8);

  return decrypted;
};