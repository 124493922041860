import React from 'react'
import date from '@/endpoints/json/home.json';
import "./OfferZone.css";
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '@/assets/css/slick.css'
import { useLanguageContext } from '@/hooks/context-hooks';
import { OFFERZONE } from '@/_constant/urlconstants';
import { Link } from 'react-router-dom';

export default function OfferZone() {

  const { language } = useLanguageContext();

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    transitionTime: 30,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ],
  };

  return (
    <>
      <section className="home-offerzone py-5">
        <div className="container text-center">
          <h4 className="kg-title kg-white">{language("label.offer_zone")}</h4>
          <div className="py-5">
            <Slider {...sliderSettings}>
              {date?.offers?.images?.map((item, index) => (
                <div key={index} className="">
                  <img src={item} alt="livestock" className='card-img' />
                </div>
              ))}
            </Slider>
          </div>
          <div className='w-100 text-center text-lg-end pt-lg-2 pt-5'>
            <Link to={OFFERZONE} className="kg-white kg-link">{language("label.view_all")}</Link>
          </div>
        </div>
      </section>
    </>
  )
}
