import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loginPopup:false,
  type:""
}

export const loginSlice = createSlice({
 name: 'loginSlice',
 initialState,
 reducers:{
  setLoginPopup:(state ,action)=>{
     state.loginPopup = action.payload;
  } ,
  setopenMenuBar:(state ,action)=>{
    state.type = action.payload;
  }
 }
})

export const {actions, reducer} = loginSlice;
export const {setLoginPopup,setopenMenuBar} = actions;
export const getLoginFromState = (state) =>{return state.loginSlice.loginPopup};
export const getType = (state) =>{return state.loginSlice.type};
export default reducer;
