// export function  getPageForCategory(categoryId, categoryFormType) {
  
//   if (categoryId >= 20 && categoryId <= 22) {
//     return 'balers';
//   }
//   if (categoryId >= 59 && categoryId <= 61) {
//     return 'balersTraders';
//   } else if (categoryId === 4) {
//     return 'combineHarvester';
//   } else if (categoryId >= 5 && categoryId <= 11 || categoryId === 14) {
//     return 'harvesterPotato';
//   } else if (categoryId === 12 || categoryId === 13) {
//     return 'harvesterCoconut';
//   } else if (categoryId === 43) {
//     return 'combineHarvesterTraders';
//   } else if ((categoryId >= 44 && categoryId <= 50) || categoryId === 53) {
//     return 'harvesterTraders';
//   } else if (categoryId >= 51 && categoryId <= 52) {
//     return 'coconutHarvesterTraders';
//   } else if (categoryId >= 16 && categoryId <= 18) {
//     return 'reaper';
//   } else if (categoryId >= 55 && categoryId <= 57) {
//     return 'reaperTrader';
//   } else if ((categoryId >= 24 && categoryId <= 29) || (categoryId >= 31 && categoryId <= 38)) {
//     return 'farmingImp';
//   } else if (categoryId >= 63 && categoryId <= 78) {
//     return 'farmingImpTraders';
//   } else if (categoryId === 2) {
//     return 'tractor';
//   } else if (categoryId === 41) {
//     return 'tractorTrader';
//   } else if ((categoryId >= 81 && categoryId <= 85) ||
//       (categoryId >= 87 && categoryId <= 90) ||
//       (categoryId >= 92 && categoryId <= 96)) {
//     return 'tyre';
//   } else if ((categoryId >= 115 && categoryId <= 117) || (categoryId >= 119 && categoryId <= 121)) {
//     return 'property';
//   } else if (categoryId >= 123 && categoryId <= 124) {
//     return 'housePropertySale';
//   } else if (categoryId > 182 && categoryId < 188) {
//     return 'horseTraders';
//   } else if (categoryId >= 107 && categoryId <= 112) {
//     return categoryFormType === "Sell" ? 'generalCropFruit' : 'crop';
//   } else if (categoryId === 101) {
//     return 'horse';
//   } else if (categoryId === 307 || categoryId === 308) {
//     return categoryFormType === "Sell" ? 'generalCropFruit' : 'fruitsVeg';
//   } else if (categoryId === 105) {
//     return 'dog';
//   } else if (categoryId === 98) {
//     return 'cow';
//   } else if (categoryId === 99) {
//     return 'ox';
//   } else if ((categoryId >= 128 && categoryId <= 130) ||
//       (categoryId >= 310 && categoryId <= 312) ||
//       (categoryId >= 313 && categoryId <= 315)) {
//     return 'pumpMotor';
//   } else if ((categoryId >= 141 && categoryId <= 149) ||
//       (categoryId >= 151 && categoryId <= 160) ||
//       (categoryId >= 162 && categoryId <= 165)) {
//     return 'livestockFeed';
//   } else if (categoryId >= 171 && categoryId <= 174) {
//     return 'biomassTrader';
//   } else if (categoryId >= 176 && categoryId <= 181) {
//     return 'silage';
//   } else if (categoryId >= 189 && categoryId <= 205) {
//     return 'machineryService';
//   } else if (categoryId === 98 || categoryId === 99 || categoryId === 100) {
//     return 'buffalo';
//   } else if (categoryId === 167) {
//     return 'pesticidesDealer';
//   } else if (categoryId === 301) {
//     return 'fertilizersDealer';
//   } else if (categoryId === 302) {
//     return 'seedsDealer';
//   } else if (categoryId === 168) {
//     return 'milkDiary';
//   } else if (categoryId === 169) {
//     return 'milkPlant';
//   } else if (categoryId === 103) {
//     return 'goat';
//   } else if (categoryId === 104) {
//     return 'sheep';
//   } else if (categoryId === 102) {
//     return 'camel';
//   } else if (categoryId === 227) {
//     return 'veterinary';
//   } else if ((categoryId >= 208 && categoryId <= 217) || categoryId === 219) {
//     return 'harvesting';
//   } else if (categoryId >= 219 && categoryId <= 222) {
//     return 'harvestingReaper';
//   } else if ((categoryId >= 224 && categoryId <= 226)) {
//     return 'harvestingBaler';
//   } else if (categoryId === 241 || categoryId === 242) {
//     return 'spray';
//   } else if (categoryId >= 235 && categoryId <= 239) {
//     return 'ambulance';
//   } else if (categoryId >= 229 && categoryId <= 233) {
//     return 'transporters';
//   } else if (categoryId === 244 || (categoryId >= 303 && categoryId <= 306)) {
//     return 'transporterAgency';
//   } else if (categoryId >= 246 && categoryId <= 249) {
//     return 'boreWell';
//   } else if (categoryId >= 278 && categoryId <= 284) {
//     return 'weldingFab';
//   } else if (categoryId >= 275 && categoryId <= 276) {
//     return 'horseDogTrainer';
//   } else if (categoryId === 273) {
//     return 'naturalLiveStockBreeding';
//   } else if (categoryId === 272) {
//     return 'artificialLiveStockBreeding';
//   } else if (categoryId === 268) {
//     return 'artificialHorseBreeding';
//   } else if (categoryId === 270) {
//     return 'naturalHorseBreeding';
//   } else if (categoryId === 251) {
//     return 'irrigationWaterTesting';
//   } else if (categoryId === 252) {
//     return 'manureWaterTesting';
//   } else if (categoryId === 253) {
//     return 'drinkingWaterTesting';
//   } else if (categoryId === 254) {
//     return 'soilTesting';
//   } else if ((categoryId >= 257 && categoryId <= 259) ||
//       (categoryId >= 261 && categoryId <= 263) ||
//       (categoryId >= 265 && categoryId <= 266) ||
//       categoryId === 269) {
//     return 'motorWaterPumpRepair';
//   } else {
//     return "general";
//   }
// }
export function  getPageForCategory(categoryId, type) {

  if (categoryId >= 20 && categoryId <= 22) {
    return "Balers";
  }
  if (categoryId >= 59 && categoryId <= 61) {
    return "Balers_trader";
  } else if (categoryId >= 4 && categoryId <= 14) {
    return "harvester";
  } else if (categoryId >= 43 && categoryId <= 53) {
    return "harvester_traders";
  } else if (categoryId >= 16 && categoryId <= 18) {
    return "reaper";
  } else if (categoryId >= 55 && categoryId <= 57) {
    return "reaper_trader";
  } else if ((categoryId >= 24 && categoryId <= 39) || (categoryId >= 361 && categoryId <= 374)) {
    return "FI";
  } else if (categoryId >= 63 && categoryId <= 78) {
    return "FI_traders";
  } else if (categoryId === 2) {
    return "tractor";
  } else if (categoryId === 41) {
    return "tractor_trader";
  } else if (
    (categoryId >= 81 && categoryId <= 85) ||
    (categoryId >= 87 && categoryId <= 90) ||
    (categoryId >= 92 && categoryId <= 96)
  ) {
    return "tyre";
  } else if (
    (categoryId >= 115 && categoryId <= 117) ||
    (categoryId >= 119 && categoryId <= 121) ||
    (categoryId >= 123 && categoryId <= 124)
  ) {
    return "property";
  } else if (categoryId < 40) {
    return categoryId;
  } else if (categoryId > 182 && categoryId < 188) {
    return "horse_traders";
  } else if (categoryId >= 107 && categoryId <= 112) {
    if (type === "cropFruit") {
      return "crop";
    }
    return "crop_reg";
  } else if (categoryId === 307 || categoryId === 308) {
    if (type === "cropFruit") {
      return "fruits_veg";
    }
    return "fruits_veg_reg";
  } else if (categoryId === 101) {
    return "horse";
  } else if (categoryId === 105) {
    return "dog";
  } else if (categoryId === 98) {
    return "cow";
  } else if (categoryId === 99) {
    return "ox";
  } else if ((categoryId >= 128 && categoryId <= 130)) {
    return "domestic_pumpmotor";
  } else if((categoryId >= 310 && categoryId <= 312)) {
    return "agriculture_pumpmotor";
  } else if((categoryId >= 313 && categoryId <= 315)) {
    return "industrial_pumpmotor";
  } else if (
    (categoryId > 140 && categoryId < 150) ||
    (categoryId >= 151 && categoryId <= 160) ||
    (categoryId >= 162 && categoryId <= 165)
  ) {
    return "livestock_feed";
  } else if (categoryId > 170 && categoryId < 175) {
    return "biomass_trader";
  } else if (categoryId >= 176 && categoryId <= 181) {
    return "silage";
  } else if (categoryId >= 246 && categoryId <= 249) {
    return "boreWell";
  }else if (categoryId >= 330 && categoryId <= 347) {
    return "bankServices";
  } else if (categoryId >= 278 && categoryId <= 284) {
    return 'welding_fab';
  } else if (categoryId >= 275 && categoryId <= 276) {
    return 'horseDogTrainer';
  } else if (categoryId >= 189 && categoryId <= 205) {
    return "machinery_service";
  } else if (categoryId === 100) {
    return "buffalo";
  } else if (categoryId === 167 || categoryId === 301 || categoryId === 302) {
    return "PFS";
  } else if (categoryId === 168) {
    return "milk_diary";
  } else if (categoryId === 169) {
    return "milk_plant";
  } else if (categoryId === 103) {
    return "goat";
  } else if (categoryId === 104) {
    return "sheep";
  } else if (categoryId === 102) {
    return "camel";
  } else if (categoryId === 227) {
    return "Veterinary";
  } else if (categoryId === 272) {
    return 'artificial_live_stock_breeding';
  } else if (categoryId === 273) {
    return 'natural_live_stock_breeding';
  } else if (categoryId === 268) {
    return 'artificial_horse_breeding';
  } else if (categoryId === 270) {
    return 'natural_horse_breeding';
  } else if (categoryId === 251) {
    return 'irrigation_water_testing';
  } else if (categoryId === 252) {
    return 'manure_water_testing';
  } else if (categoryId === 253) {
    return 'drinking_water_testing';
  } else if (categoryId === 254) {
    return 'soil_testing';
  } else if ((categoryId >= 257 && categoryId <= 259) ||
      (categoryId >= 261 && categoryId <= 263) ||
      (categoryId >= 265 && categoryId <= 266) ||
      categoryId === 269) {
    return 'motor_water_pumpRepair';
  } else {
    return "general";
  }
}
