import React from "react";
import { Outlet } from "react-router";
import { useAdsRefresh } from "../../hooks/ads-refresh";
import TopBanner from "../Common/ads/topbanner/TopBanner";
import BottomBanner from "../Common/ads/bottombanner/BottomBanner";
import OfferZone from "../public/plugins/OfferZone/OfferZone";

const BasePosting = () => {
    
    useAdsRefresh();

    return (
        <>
            <TopBanner />
            <div className="Ad-Zone_Listings">
                <div className="Ad_Zone_Listings_content">
                    <Outlet />
                </div>
            </div>
            <BottomBanner />
            <OfferZone />
        </>
    )
};

export default BasePosting;