import React , {useEffect} from 'react'
import $ from 'jquery';
import "./loader.css";
export default function Loader() {
  
  useEffect(()=>{
     if($('#preloader').has('active')){
      setTimeout(function () {
        var preloader = document.getElementById('preloader');
        if (preloader) {
          preloader.style.display = 'none';
        }
      }, 1500);
     }
  },[$('#preloader').has('active')])
  

  return (
    <div id="preloader">
        <div className="preloader-container">
          <div className="preloader-animation"></div>
        </div>
    </div>
  )
}
