import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: []
}


export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    }
  }
})
export const { actions, reducer } = userSlice;
export const { setUserData } = actions;
export const getUserData = (state) => { return state.userSlice.data; };
export default reducer;
