import axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import { API_PUBLIC_HOST } from "@/_constant/urlconstants";

const LanguageContext = createContext();

function LanguageProvider({ children }) {

    const [languageData, setLanguageData] = useState(new Map());
    const [defaultLang, setDefaultLang] = useState('English');

    function changeLanguage(lang='English'){
        setDefaultLang(lang);
    }

    function language(key = '') {
        const value = languageData.get(key);
        if(value){
            return value;
        }
        return null;
    }

    function arrayToMap(dataList){
        const langMap = new Map();
        dataList.forEach(el => langMap.set(el.displayCode, el.value));
        setLanguageData(langMap)
    }

    const fetchLanguageData = useCallback(() =>{
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${API_PUBLIC_HOST}/lookup/language-pack?display_code=*&languagePackType=NORMAL&language=${defaultLang}`,
            headers: {
                accept: "application/json",
            },
        };

        axios.request(config).then((response) => {
            const dataList = response?.data?.response;
            arrayToMap(dataList);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [defaultLang])

    useEffect(() => {
        fetchLanguageData()
    }, [fetchLanguageData])
    
    const languageContext = {
        languageData,
        defaultLang,
        language,
        changeLanguage

    }

    return (
        <LanguageContext.Provider value={languageContext}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageContext;
export { LanguageProvider };