import { Auth } from "aws-amplify";
import $ from 'jquery';
import {getUserDetails} from '../../_service/common/common'
import {store} from '../../store/store'
import { setUserData } from "../../store/reducer/userSlice/userSlice";
import {setLoginPopup} from '../../store/reducer/loginSlice/loginSlice';
import {decryptUsingAES256WithCBC ,encryptUsingAES256WithCBC} from '../../_constant/app.encriptor';
import Swal from "sweetalert2";
import { Error } from "../../_service/notification/notification";

// export async function register(name,phone_number,password,confpassword,access,handleRegisterResult,handleLoginResult) {
//   try {
//     if (name === "" || phone_number.length < 5 || password === "") {
//       $("#register_error").show();
//       $("#register_error").text("Please fill all the fields to register.");
//     } else {
//       $("#register_error").hide();
//       const { user } = await Auth.signUp({
//         username: phone_number,
//         phone_number: phone_number,
//         password: password,

//         attributes: {
//           name: name,
//           "custom:access": access,
//         },
//       });
//       localStorage["userpass"] = password;
//       handleRegisterResult(phone_number, true, user);
//     }
//   } catch (error) {
//     if (
//       error.message.includes("User exists") ||
//       error.message.includes("already exists")
//     ) {
//       try {
//         const user = await Auth.signIn(phone_number, password);

//         if (user.signInUserSession != null) {
//           handleLoginResult(phone_number, true, user);
//         } else {
//           Auth.resendSignUp(phone_number);
//         }
//       } catch (e) {
//         Auth.resendSignUp(phone_number);
//       }
//       localStorage["userpass"] = password;
//       handleRegisterResult(phone_number, true, "");
//     } else {
//       localStorage["userpass"] = "";
//       handleRegisterResult(phone_number, false, error);
//     }
//   }
// }

export async function ResendSignUp(phone_number, handleRegisterResult) {
  Auth.resendSignUp(phone_number);
  handleRegisterResult(phone_number, true, "");
}

export async function signUp(username, password, email, name, access, handleLoginResult) {
  try {
    const { user } = await Auth.signUp({
      username: username,
      password: password,
      attributes: {
        name: name,
        'custom:access': access.toString(),
      },
    });
    return user;
  } catch (error) {
    if (error.code === 'UsernameExistsException') {
      try {
        const user = await Auth.signIn(username, password);
        if (user.attributes.phone_number_verified) {
          Error("User already exists");
        } else {
          handleLoginResult(username, true, user);
        }
      } catch (signInError) {
        if (signInError.message.includes('User is not confirmed')) {
          handleLoginResult(username, false, null);
        } else {
          if(signInError.code === 'NotAuthorizedException' || signInError.code === 'UsernameExistsException'){
            Error("User already exists");
          } else {
            console.log('Error signing up:', signInError);
          }
        }
      }
    } else {
      if(error.code === 'NotAuthorizedException' || error.code === 'UsernameExistsException'){
        Error("User already exists");
      } else {
        console.log('Error signing up:', error);
      }
    }
  }
}

export async function getUserDetailsByUsername(username) {
  try {
    // Fetch the user by username
    //const users = await Auth.confirmSignUp(username);
    const users = await Auth.confirmSignUp(username, '000000', {forceAliasCreation: false})
    // Check if a user with the specified username exists
    if (users && users.length > 0) {
      const user = users[0];
      console.log('User details:', user.Attributes);
    } else {
      console.log('User not found with the specified username.');
    }
  } catch (error) {
    console.error('Error fetching user details:', error);
    
    console.error('Error fetching user details:', error.code);
  }
}

export async function confirmSignUp(username, code, callback , e) {
  try {
    await Auth.confirmSignUp(username, code)
      .then((data) => {
        callback(true, username, e)
      }).catch((err) => {
        callback(true, err)
    });
  } catch (error) {
    callback(false, error);
  }
}

export async function resendConfirmationCode(username, callback) {
  try {
    if (username.length < 5) {
      $("#confrmaccount_error").show();
      $("#confrmaccount_error").text("Please provide valid phone number");
    } else {
      $("#confrmaccount_error").hide();
      await Auth.resendSignUp(username)
        .then((data) => (callback != null ? callback(true, username) : null))
        .catch((err) => (callback != null ? callback(false, err) : null));
    }
  } catch (error) {
    callback(false, error);
  }
}

export async function signIn(username, password, handleLoginResult, event) {
  if(event){$(event.target).attr("disabled", true);}
  $("#preloader").show();
  try {
    if (password == "" || username.length < 5 || username == "") {
      $("#login_error").show();
      $("#login_error").text("Please fill all the fields to register.");
      $(event.target).attr("disabled", false);
    } else {
     const user  = await Auth.signIn(username, password);
     const userDetails =await getUserDetails()
     setUserDetails(user , userDetails);
     store.dispatch(setLoginPopup(false))
    }
  } catch (error) {
    $("#login_error").show();
    $("#login_error").text(error.message.trim());
    $(event.target).attr("disabled", false);
  }
}

export async function signVerify(username, password, handleLoginResult, event) {
  // $(event.target).attr("disabled", true);
  $("#preloader").show();
  try {
    if (password === "" || username.length < 5 || username === "") {
      // $("#login_error").show();
      // $("#login_error").text("Please fill all the fields to register.");
      // $(event.target).attr("disabled", false);
    } else {
      $("#preloader").hide();
      return await Auth.signIn(username, password);
    }
  } catch (error) {
    $("#login_error").show();
    $("#login_error").text(error.message.trim());
    // $(event.target).attr("disabled", false);
  }
}

export async function resendOTPForAuth(phone_number) {
  try {
    await Auth.resendSignUp(phone_number);
    $("#resentotpmsg").show();
    $("#resentotpmsg").text("Code resent successfully");
  } catch (err) {
    $("#resentotpmsg").show();
    $("#resentotpmsg").text("Error resending code: " + err);
  }
}

export async function verifyCurrentUserAttribute(phone_number) {
  try {
    await Auth.verifyCurrentUserAttribute("phone_number");
    console.log("a verification code is sent");
  } catch (err) {
    console.log("failed with error: ", err);
  }
}

export async function verifyCurrentUserAttributeSubmit(
  phone_number,
  code,
  callback
) {
  try {
    await Auth.verifyCurrentUserAttributeSubmit("phone_number", code);
    callback(true, phone_number);
  } catch (error) {
    console.log("failed with error: ", error);
    callback(false, phone_number, error);
  }
}

export function getCurrentSession(callback) {
  try {
    Auth.currentSession()
      .then((data) => {
        let idToken = data.getIdToken();
        var payload = idToken.payload;
        let user = {
          name: payload["name"],
          phone_number: payload["phone_number"],
          "custom:access": payload["custom:access"],
        };
        callback(true, user, idToken["jwtToken"]);
      })
      .catch((err) => {
         Swal.fire('info',"session expired please login again", 'info')
         Auth.signOut();
         store.dispatch(setLoginPopup(false));
         store.dispatch(setUserData([]));
         if(!(window.location.href.includes("sell") && window.location.href.includes("buy") && window.location.href.includes("servies"))){
          window.location.href = "/";
         }
         
        //  setTimeout(()=> {window.location.href = "/";}, 700)
      });
  } catch (err) {
    Swal.fire('info',"session expired please login again", 'info')
    Auth.signOut();
    store.dispatch(setUserData([]));
    if(!(window.location.href.includes("sell") && window.location.href.includes("buy") && window.location.href.includes("servies"))){
      window.location.href = "/";
     }
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
    Auth.signOut();
    localStorage.removeItem('userData');
    store.dispatch(setUserData([]));
    window.location.href = "/";
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export async function changePassword_initiate(username, callback) {
  try {
    if (username.length < 5) {
      $("#chpassword_1_error").show();
      $("#chpassword_1_error").text("Please provide valid phone number");
    } else {
      $("#chpassword_1_error").hide();
      Auth.forgotPassword(username)
        .then((data) => (callback != null ? callback(true, username) : null))
        .catch((err) => (callback != null ? callback(false, err) : null));
    }
    // Send confirmation code to user's email
  } catch (error) {
    callback(false, error);
  }
}

export async function changePassword_finalize(
  username,
  code,
  new_password,
  callback
) {
  try {
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => callback(true))
      .catch((err) => callback(false, err));
  } catch (error) {
    callback(false);
  }
}

export async function changePasswordWithOldPsd(
  old_password,
  new_password,
  callback
) {
  try {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, old_password, new_password);
      })
      .then((data) => callback(true))
      .catch((err) => callback(false, err));
  } catch (error) {
    callback(false);
  }
}

const setUserDetails= (user , response , event) => {
  const setUserDetails = response?.response;
  let currentSession = {};
  for (let x in setUserDetails) {
    currentSession[x] = setUserDetails[x];
  }
  localStorage.setItem('userData',encryptUsingAES256WithCBC(currentSession));
  store.dispatch(setUserData(currentSession))
  //window.location.href.includes("sell") ?  reload();
}

export function isLoggedInUser() {
  try {
    Auth.currentSession()
      .then((user) => {
        const data = decryptUsingAES256WithCBC(localStorage.getItem('userData'))
        localStorage.getItem('userData')
        store.dispatch(setUserData(data))
      }).catch((err) => {
        
      });
  } catch (error) {
    // Auth.signOut()
  }
}

export const getDefaultValue = (value) => {
  return value === undefined || value === null || value === 'null' || value === '' ? 0 : value;
};

export async function signVerifyIn(username, password, handleLoginResult, event) {
  $(event.target).attr("disabled", true);
  $("#preloader").show();
  try {
    if (password == "" || username.length < 5 || username == "") {
      $("#login_error").show();
      $("#login_error").text("Please fill all the fields to register.");
      $(event.target).attr("disabled", false);
    } else {
      const user  = await Auth.signIn(username, password);
      return user;
    }
  } catch (error) {
    $("#login_error").show();
    $("#login_error").text(error.message.trim());
    $(event.target).attr("disabled", false);
  }
}


// window.register = register;
// window.signIn = signIn;
// window.confirmSignUp = confirmSignUp;
// window.resendConfirmationCode = resendConfirmationCode;
// window.verifyCurrentUserAttributeSubmit = verifyCurrentUserAttributeSubmit;
// window.signUp = signUp;
// window.resendOTPForAuth = resendOTPForAuth;
// window.changePasswordWithOldPsd = changePasswordWithOldPsd;
// window.getCurrentSession = getCurrentSession;
// window.signOut = signOut;
// window.changePassword_initiate = changePassword_initiate;
// window.changePassword_finalize = changePassword_finalize;
// window.ResendSignUp = ResendSignUp;
