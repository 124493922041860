import React, { useEffect, useState, } from 'react';
import "./categories.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { getCurrentSession } from "../../../endpoints/amplify/auth";
import { API_PUBLIC_HOST, S3_HOST } from '../../../_constant/urlconstants';
import { getPageForCategory } from "../../../_constant/Utils";
import { PREFIX } from "../../../_constant/urlconstants";
import TopBanner from '../../Common/ads/topbanner/TopBanner'
import RightBanner from '../../Common/ads/rightbanner/RightBanner'
import BottomBanner from '../../Common/ads/bottombanner/BottomBanner'
import { useSelector, useDispatch } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import MetaTags from '../../../meta';
import { getSellCategoriesData } from '../../../store/reducer/categoriesListSlice/categories';
import { getUserData } from '../../../store/reducer/userSlice/userSlice';
import { setLoginPopup } from '../../../store/reducer/loginSlice/loginSlice';
import { useAdsRefresh } from '../../../hooks/ads-refresh';
import { useLanguageContext } from '../../../hooks/context-hooks';
import { swapLabel } from '@/utility/common-utils';

function Categories() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUserData);
  const { language } = useLanguageContext();
  const [currentSessionData, setCurrentSessionData] = useState("");
  const [subCategoriesName, setSubCategoriesName] = useState("");
  const [parentCategories, setParentCategories] = useState("");
  const [subCategories, setSubCategories] = useState("");
  const sellCategoriesList = useSelector(getSellCategoriesData);
  
  useEffect(() => {
    if (Object.keys(userDetails).length > 0 && state !== null && window.location.href.includes("sell")) {
      setCurrentSessionData(state ? JSON.parse(state.item) : null);
    } else if (Object.keys(userDetails).length == 0 && state != null && sellCategoriesList.length > 0) {
      // const id= (window.location.href.includes("sell") ? window.location.href.split("/"): window.location.href.split("/"))
      // const item = Object.values(sellCategoriesList).map(category => id[id.length-1]);
      setCurrentSessionData(state ? JSON.parse(state.item) : null);
    } else if (window.location.href.includes("sell") && state == null) {
      const url = new URL(window.location.href);
      const states = {
        category_id: url.searchParams.get("id"),
        editType: atob(url.searchParams.get("@"))
      }
      setCurrentSessionData(states);
    }

  }, [state, userDetails])

  useEffect(() => {
    if (currentSessionData != "") {

      if (userDetails.length > 0) {
        getCurrentSession(getListCategoryTree);
      } else {
        getListCategoryTree();
      }
    }
  }, [currentSessionData]);

  const getListCategoryTree = (success, user, jwtToken) => {
    axios.get(API_PUBLIC_HOST + '/lookup/getCategoryTree?categoryId=' + ((currentSessionData?.editType === "general" || currentSessionData?.editType === "gen") ? currentSessionData?.category_id : currentSessionData?.data?.category_id), {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + jwtToken,
    }).then(response => {
      setParentCategories(response?.data?.response[0]);
      handleChangeFilterCategories(response?.data?.response[0], true)
    }).catch(error => {

    });
  };

  const handleChangeFilterCategories = (data, type) => {
    getFilterCategories(data, type)
  };

  const getFilterCategories = (item, type) => {
    const data = JSON.stringify({
      "parent_id": item?.id,
      "is_active": true,
      "level": -1
    });
    const config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: API_PUBLIC_HOST + '/category/filterCategories',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response?.data?.response.length > 0 && item?.id != 284) {
          setSubCategories(response?.data?.response);
          if (!type) {
            setSubCategoriesName(item);
          }
        } else {
          if (Object.keys(userDetails).length > 0) {
            handleChangeOpenPostForm(item)
          } else {
            dispatch(setLoginPopup(true));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeOpenPostForm = (item) => {
    const previousPath = window.location.href.split("/")
    const path = getPageForCategory(item?.id, "")
    const url = PREFIX + path
    const items = {
      UserPlanId: currentSessionData?.editType === "general" ? currentSessionData?.UserPlanId : currentSessionData?.data?.user_plan_id,
      item: item,
      route: "sell/" + previousPath[previousPath.length - 1],
      parentCategories: parentCategories,
      subCategoriesName: subCategoriesName,
      data: null,
      editType: currentSessionData?.editType,
      userAccessId: currentSessionData?.data?.access_id
    }
    item = items
    navigate(url, { replace: true, state: JSON.stringify(item) });
  }


  const goBack = () => {
    if (subCategoriesName != "") {
      setSubCategories("")
      setSubCategoriesName("");
      getFilterCategories(parentCategories, true)
    } else {
      window.location.href = "/home";
    }
  };

  useAdsRefresh();

  return (
    <div>
      <MetaTags title={currentSessionData?.name ? currentSessionData?.name : currentSessionData?.data?.name} description={currentSessionData?.name ? currentSessionData?.name : currentSessionData?.data?.name} />
      <main className="main_content">
        <TopBanner />
        <section className="lead_section">
          <div className="lead_main">
            <div className="container">
              <div className="lead_bg">
                <div className="row">
                  <div className="col-md-8 mb-4">
                    <div className="laed_head">
                      {parentCategories && <h2 id="headingpanelid"><span>{language("label.choose_your")} </span>
                        <span style={{ "fontSize": "38px" }}><b>{language(swapLabel(parentCategories?.displayCode))}</b></span>
                      </h2>
                      }
                      <p id="breadcrumbid" className="page_titall">
                        {parentCategories && (
                            <span>
                              {  language(swapLabel(parentCategories?.displayCode))}
                            </span>
                        )}
                        {subCategoriesName && (
                          <>
                            <i className="fas fa-chevron-right" style={{ marginRight: "8px" }}></i>
                            <span>
                              {language(subCategoriesName?.displayCode)}
                            </span>
                          </>
                        )}
                      </p>

                    </div>
                    <div className="row p-0 machine_main" id="categorycontainerId">
                      {subCategories && subCategories.length > 0 && subCategories.map((category, i) => (
                        <div className="col-md-3 text-center cursor-pointer pb-4" key={i}>
                          <button type='button' className="btn machine_img_box" onClick={() => handleChangeFilterCategories(category, false)}>
                            <img src={S3_HOST + category?.thumbnail_url} alt={category?.name} />
                            <p lang="en">
                              {language(category?.displayCode)}
                            </p>
                          </button>
                        </div>
                      ))}
                    </div>
                    <div><button type="button" className='btn' onClick={() => goBack()}><i className='bi bi-arrow-left me-1'></i>Go Back</button></div>
                  </div>

                  {/* /********** right banner */}
                  <div className="col-md-4 mb-4">
                    <RightBanner />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BottomBanner />
      </main>
    </div>
  );
}

export default Categories;
