import React, {useState, useRef } from 'react';
import './verifyOTP.css';
import TopBanner from '../../Common/ads/topbanner/TopBanner';
import BottomBanner from '../../Common/ads/bottombanner/BottomBanner';
import RightBanner from '../../Common/ads/rightbanner/RightBanner';
import { useLocation, useNavigate } from "react-router-dom";
import {resendConfirmationCode, confirmSignUp,signIn } from '../../../endpoints/amplify/auth'
import { useAdsRefresh } from '../../../hooks/ads-refresh';
import { useLanguageContext } from '../../../hooks/context-hooks';

const VerifyOTP = () => {
    const { language } = useLanguageContext();  

    const { state } = useLocation();
    const navigate = useNavigate()
    const [otpInputs, setOtpInputs] = useState(['', '', '', '', '', '']);
    const otpInputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()));

    const [currentRoutelanguageList, setCurrentRoutelanguageList] = useState(state ? JSON.parse(state) : null);
    if(currentRoutelanguageList?.route == null){
      Error("this page can't be accessed");
      window.location.href = "/";
    }
      const handleOtpInputChange = (index, value) => {
        const newOtpInputs = [...otpInputs];
        newOtpInputs[index] = value;
        setOtpInputs(newOtpInputs);
      
        if (/^\d$/.test(value) && index < otpInputs.length - 1) {
          otpInputRefs.current[index + 1].current.focus();
        }
      };
      const handleOtpInputKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otpInputs[index] && index > 0) {
          e.preventDefault();
          otpInputRefs?.current[index - 1].current.focus();
        } 
      };

    const handleVerifyOTP = (e) => {
        const otp = otpInputs.join('');
        confirmSignUp(currentRoutelanguageList?.phoneNumber,otp, handleOtpStatus,e)
    };

    const  handleOtpStatus = (type, username , e) => {
      if(type){
        signIn(currentRoutelanguageList.phoneNumber , currentRoutelanguageList.password, e);
      }
    }

    const handleResendOTP = () => {
        setOtpInputs(['', '', '', '', '', '']);
        resendConfirmationCode(currentRoutelanguageList?.phoneNumber, resendOTPForAuth)
    };
    const resendOTPForAuth=(type, user)=>{
      if(!type){
        Error('due to server issue please try again later...')
      }
    }

    const handleChangePassword =(type , user)=>{
      if(type){
        
         const item = {
           username : user,
           route : 'password',
           phoneNumber:currentRoutelanguageList?.phoneNumber,
         }
         navigate("/resetpassword", {replace: true,state:JSON.stringify(item)})
      }
    }
    
    useAdsRefresh();

    return (
     <main className="main_content">
     <TopBanner />
      <section className="lead_section">
          <div className="lead_main">
              <div className="container">
                  <div className="lead_bg">
                      <div className="row">
                          <div className="col-md-8 mb-4">
                              <div className="laed_head mb-5">
                                <h2> <span lang="en" className="register_text">{language("verify_otp")}</span></h2>
                              </div>
                              <div className="register_main">
                              <p lang="English" className="verification">{language("We_have_sent_a_verification_code_to")}</p>
                              <p lang="English" className="verification">{currentRoutelanguageList?.phoneNumber}</p>
                              <div className="re_form text-center">
                              <div id="otp" className="inputs d-flex flex-row justify-content-center mb-4">
                                  {otpInputs?.map((value, index) => (
                                      <input
                                      key={index}
                                      type="text"
                                      className="m-2 text-center form-control rounded otpBox"
                                      maxLength="1"
                                      value={value}
                                      onChange={(e) => handleOtpInputChange(index, e.target.value, e)}
                                      onKeyDown={(e) => handleOtpInputKeyDown(e, index)}
                                      ref={otpInputRefs.current[index]}/>
                                  ))}
                              </div>
                              
                                <div className="text-center">
                                <button className="send_otp_reg" onClick={(e)=>handleVerifyOTP(e)}>
                                {language("verify_otp")}
                                </button>
                                </div>
                              <div className="link_ver">
                                  <span className="mr-2">
                                  
                                  {language("label.did_nt_receive_code")}
                                  </span>
                                    <a onClick={(e)=>handleResendOTP(e)}>{language("label.resend_now")}</a>
                              </div>
                                <div id="resentotpmsg" style={{"display":"none"}} className="alert_m"></div>
                                <hr />
                                <p lang="English" className="member">
                                {language("If_you_are_member_please")}
                                </p>

                                <div className="text-center">
                                              <button lang="English" languageList-bs-toggle="modal" languageList-bs-target="#login_popup" className="reg_login mb-2 text-center">
                                              {language("label.login")}
                                              </button>
                                          </div>
                              </div> 
                              </div>
                          </div>
                          <div className="col-md-4 mb-4">
                              <RightBanner />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <BottomBanner />
    </main>
    );
};

export default VerifyOTP;