import axios from 'axios';
import {getCurrentSession} from '../../endpoints/amplify/auth';
import { API_PUBLIC_HOST } from '../../_constant/urlconstants';
import {Error} from '../notification/notification';

export const  getBrandList =(brandName)=>{
 return axios.get(API_PUBLIC_HOST + '/lookup/brands?group='+brandName)
  .then(response => {
    return response
}).then((resp)=> {
  return resp?.data.response
}).catch(error => {
     
  });
}

export const  saveListItem =(data)=>{
  return new Promise((resolve, reject) => {
    getCurrentSession((success, user, jwtToken) => {
      const url = `${API_PUBLIC_HOST}/listing/saveListing`
      axios({
        method: "POST",
        url: url,
        data: data,
        headers: {
          Accept: "application/json",
        "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("Server error:", error.response.data);
            Error("Something went wrong. Please try again later.");
          } else if (error.message === "Network Error") {
            console.log("Network error:", error.message);
            Error("There is a problem with your internet connection.");
            window.location.href = '/';
          } else {
            reject(error);
          }
        });
    });
  });
}

export const saveAddress = (addNewAddress) => {
  return new Promise((resolve, reject) => {
    getCurrentSession((success, user, jwtToken) => {
      const url = `${API_PUBLIC_HOST}/user/addAddress`;
      axios({
        method: "POST",
        url: url,
        data: JSON.stringify(addNewAddress),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("Server error:", error.response.data);
            Error("Something went wrong. Please try again later.");
          } else if (error.message === "Network Error") {
            console.log("Network error:", error.message);
            Error("There is a problem with your internet connection.");
            window.location.href = '/';
          } else {
            reject(error);
          }
        });
    });
  });
};

export const getIdListData = (id) => {
  return new Promise((resolve, reject) => {
    getCurrentSession((success, user, jwtToken) => {
      const url = `${API_PUBLIC_HOST}/listing/viewListing?`;
      var URL = url + "listId=" + id;
      axios({
        method: "get",
        url: URL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => {
          resolve(response?.data?.response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("Server error:", error.response.data);
            Error("Something went wrong. Please try again later.");
          } else if (error.message === "Network Error") {
            console.log("Network error:", error.message);
            Error("There is a problem with your internet connection.");
          } else {
            reject(error);
          }
        });
    });
  });
};

export const getOfferCategories = () => {
  return axios
    .get(API_PUBLIC_HOST + "/lookup/category")
    .then((response) => {
      return response;
    })
    .then((resp) => {
      return resp?.data.response;
    })
    .catch(() => {});
};


export const getFiltersData = async (catID, values) => {
  try {
    const { success, user, jwtToken } = {} //await getCurrentSession();
    const url = `${API_PUBLIC_HOST}/listing/searchListing?categoryId=${catID}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: jwtToken,
    };  
    const response = await axios.post(url, { ...values }, { headers });
    return response.data.response;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.log("Server error:", error.response.data);
      throw new Error("Something went wrong. Please try again later.");
    } else if (error.message === "Network Error") {
      console.log("Network error:", error.message);
      throw new Error("There is a problem with your internet connection.");
    } else {
      throw error;
    }
  }
};

export const getIdAddZOneData = (id) => {
  return new Promise((resolve, reject) => {
    getCurrentSession((success, user, jwtToken) => {
      const url = `${API_PUBLIC_HOST}/product-ad/viewProductAd?`;
      var URL = url + "ProductAdId=" + id;
      axios({
        method: "get",
        url: URL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => {
          resolve(response?.data?.response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("Server error:", error.response.data);
            Error("Something went wrong. Please try again later.");
          } else if (error.message === "Network Error") {
            console.log("Network error:", error.message);
            Error("There is a problem with your internet connection.");
          } else {
            reject(error);
          }
        });
    });
  });
};

// export const getFiltersData = (catID, values) => {
//   return new Promise((resolve, reject) => {
//     getCurrentSession((success, user, jwtToken) => {
//       const url = `${API_PUBLIC_HOST}/listing/searchListing?categoryId=`+ catID;
//       const headers = {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: jwtToken,
//       };

//       // axios.post(url, {values},{ headers })
//       axios.post(url, {...values},{ headers })
//         .then((response) => {
//           resolve(response?.data?.response);
//         })
//         .catch((error) => {
//           if (error.response && error.response.status === 500) {
//             console.log("Server error:", error.response.data);
//             // reject("Something went wrong. Please try again later.");
//           } else if (error.message === "Network Error") {
//             console.log("Network error:", error.message);
//             // reject("There is a problem with your internet connection.");
//           } else {
//             reject(error);
//           }
//         });
//     });
//   });
// };



export const updateUser = (userInfo) => {
  return new Promise((resolve, reject) => {
    getCurrentSession((success, user, jwtToken) => {
      const url = `${API_PUBLIC_HOST}/user/updateUser`;
      axios({
        method: "POST",
        url: url,
        data: JSON.stringify(userInfo),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("Server error:", error.response.data);
            Error("Something went wrong. Please try again later.");
          } else if (error.message === "Network Error") {
            console.log("Network error:", error.message);
            Error("There is a problem with your internet connection.");
            window.location.href = '/';
          } else {
            reject(error);
          }
        });
    });
  });
};


export const addUserAccess = (userInfo) => {
  return new Promise((resolve, reject) => {
    getCurrentSession((success, user, jwtToken) => {
      //const url = `${API_PUBLIC_HOST}/user/addUserAccess`;
      const url = `${API_PUBLIC_HOST}/user/addOrGetUserAccess`;
      axios({
        method: "POST",
        url: url,
        data: JSON.stringify(userInfo),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("Server error:", error.response.data);
            Error("Something went wrong. Please try again later.");
          } else if (error.message === "Network Error") {
            console.log("Network error:", error.message);
            Error("There is a problem with your internet connection.");
            window.location.href = '/';
          } else {
            reject(error);
          }
        });
    });
  });
};

export const assignOTPSend = (name,email,phone,categoryId) => {
  return new Promise((resolve, reject) => {
    getCurrentSession((success, user, jwtToken) => {
      const url = `${API_PUBLIC_HOST}/otp/generateAssigneeOTP`;
      var URL = url + "?assigneeName="+name+"&categoryId="+categoryId+ "&email="+email+"&mobileNumber="+phone;
      axios({
        method: "PUT",
        url: URL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("Server error:", error.response.data);
            Error("Something went wrong. Please try again later.");
          } else if (error.message === "Network Error") {
            console.log("Network error:", error.message);
            Error("There is a problem with your internet connection.");
          } else {
            reject(error);
          }
        });
    });
  });
};

export const assignVerifyOTPSend = (otp, phone) => {
  return new Promise((resolve, reject) => {
    getCurrentSession((success, user, jwtToken) => {
      const url = `${API_PUBLIC_HOST}/otp/validateOTP`;
      var URL = url + "?otp="+otp+"&userId="+phone;
      axios({
        method: "PUT",
        url: URL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.log("Server error:", error.response.message);
            Error("Something went wrong. Please try again later.");
          } else if (error.message === "Network Error") {
            console.log("Network error:", error.message);
            Error("There is a problem with your internet connection.");
          } else {
            reject(error);
          }
        });
    });
  });
};