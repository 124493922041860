import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoriesList:[],
  sellCategories:[],
  serviceCategories:[]
}
  

export const categoriesListSlice = createSlice({
 name: 'categoriesListSlice',
 initialState,
 reducers:{
  setCategoriesData:(state ,action)=>{
       state.categoriesList = action.payload;
  },
  setSellCategoriesData:(state ,action)=>{
    state.sellCategories = action.payload;
  },
  setServiceCategoriesData:(state ,action)=>{
    state.serviceCategories = action.payload;
  }
 }
})
export const {actions, reducer} = categoriesListSlice;
export const {setCategoriesData,setServiceCategoriesData,setSellCategoriesData} = actions;
export const getCategoriesListSlice= (state) =>{return state.categoriesListSlice.categoriesList;};
export const getSellCategoriesData= (state) =>{return state.categoriesListSlice.sellCategories;};
export const getServiceCategoriesData= (state) =>{return state.categoriesListSlice.serviceCategories;};

export default reducer;
