import React, { useState } from 'react';
import TopBanner from '../../Common/ads/topbanner/TopBanner';
import BottomBanner from '../../Common/ads/bottombanner/BottomBanner';
import RightBanner from '../../Common/ads/rightbanner/RightBanner';
import { useLocation } from "react-router-dom";
import { changePassword_finalize } from '../../../endpoints/amplify/auth'
import { Success, Error } from '../../../_service/notification/notification';
import { useAdsRefresh } from '../../../hooks/ads-refresh';
import { useLanguageContext } from '../../../hooks/context-hooks';

const ResetPassword = () => {
  const { language } = useLanguageContext();
  const { state } = useLocation();
  const [currentRouteData, setCurrentRouteData] = useState(state ? JSON.parse(state) : null);
  if (!currentRouteData?.route) { window.location.href = "/"; }

  const schema = {
    "register_phone": currentRouteData?.phoneNumber,
    "otp": "",
    "newPassword": "",
    "newPassword_confirm": ""
  }
  const [selectedItem, setSelectedItem] = useState(schema);

  const inputsFromHandler = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSelectedItem((prevState) => ({ ...prevState, [name]: value, }));
  };

  const handleChangePassword = async () => {
    if (selectedItem.otp === "") {
      Error("otp can't be empty");
    } else if (selectedItem.newPassword === "") {
      Error("password can't be empty");
    } else if (selectedItem.newPassword_confirm === "") {
      Error("re password can't be empty");
    } else if (selectedItem.newPassword !== selectedItem.newPassword_confirm) {
      Error("Please ensure passwords are the same.");
    } else {
      await changePassword_finalize(selectedItem?.register_phone, selectedItem?.otp, selectedItem?.newPassword, handleChangeUserRegisterUser);
    }
  };

  const handleChangeUserRegisterUser = (type) => {
    if (type) {
      Success("password updated successfully")
      setTimeout(() => {
        window.location.href = "/"
      }, 500);

    }
    // resendConfirmationCode(username,resendOTPForAuth)
  };
  useAdsRefresh();
  
  return (
    <main className="main_content">
      <TopBanner />
      <section className="lead_section">
        <div className="lead_main">
          <div className="container">
            <div className="lead_bg">
              <div className="row">
                <div className="col-md-8 mb-4">
                  <div className="laed_head mb-5">
                    <h2><span>  {language("label.change_password")} </span></h2>
                  </div>

                  <div className="register_main">
                    <div className="re_form login">
                      <div className="form_div">
                        <label htmlFor="oldPassword" className="form-label"> {language("label.enter_otp")}<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="otp" name='otp' placeholder={language("label.enter_otp")} />
                      </div>
                      <div className="form_div">
                        <label htmlFor="newPassword" className="form-label">{language("Enter_new_password")}  <span className="text-danger">*</span></label>
                        <input type="password" className="form-control" id="newPassword" name='newPassword' placeholder={language("Enter_new_password")} />
                      </div>
                      <div className="form_div">
                        <label htmlFor="confirmPassword" className="form-label">{language("label.re_enter_password")}  <span className="text-danger">*</span></label>
                        <input type="password" className="form-control" id="confirmPassword" name='newPassword_confirm' placeholder={language("label.re_enter_password")} />
                      </div>
                      <button className="send_otp_reg" onClick={(e) => handleChangePassword(e)}>{language("label.confirm")} </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-4">
                  <RightBanner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomBanner />
    </main>
  );
};

export default ResetPassword;
