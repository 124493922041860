import { DateTime } from "luxon";

const ACCESS_TYPES = {
	0: "others",
	1: "service-provider",
	2: "agent",
	3: "farmer",
	4: "dealer"
}

export function copyToClipboard(text = "") {
  navigator && navigator.clipboard.writeText(text);
}

export function categoryDisplayCode(category) {
  if (category.category_id === '-1') {
    return "label.farmer";
  } else if (category.category_id === '-2') {
    return "label.agent";
  } else if (category.category_id === '-3') {
    return "label.others";
  } else if (category.category_id === '999') {
    return "label.assignee";
  }
  return category?.category_display_code;
}

export function accessTypeName(accessId){
	return ACCESS_TYPES[accessId];
}

export function formatTimeISOFormat(time, fromFormat) {
  return DateTime.fromFormat(time, fromFormat).toISOTime();
}

export function formatDateISOFormat(date, fromFormat) {
  return DateTime.fromFormat(date, fromFormat).toISODate();
}

export function formatTime(time, fromFormat, format) {
  return DateTime.fromFormat(time, fromFormat).toFormat(format);
}

export function formatDate(date, fromFormat, format) {
  return DateTime.fromFormat(date, fromFormat).toFormat(format);
}

export function formatDateTime(datetime, fromFormat, format) {
  return DateTime.fromFormat(datetime, fromFormat).toFormat(format);
}

export function formatFromISODate(date, format){
 return DateTime.fromISO(date).toFormat(format);
}

export function getYearMonth(date, format='yyyy-MM'){
  return DateTime.fromISO(date).toFormat(format);
}

export function minusMonth(date, fromFormat, format){
  return DateTime.fromFormat(date, fromFormat).minus({months: 1}).toFormat(format);
}

export function swapLabel(displayCode = "") {
  
  if(displayCode === 'category.machinery'){
    return 'category_machinery';
  }else if(displayCode ===  'category.machinery_traders') {
    return 'category_machinery';
  }
  return displayCode;
}