import React, { useEffect, useState } from "react";
import { getCurrentSession } from "@/endpoints/amplify/auth";
import "./profile.css";
import OfferZone from "@/_component/public/plugins/OfferZone/OfferZone";
import { useAdsRefresh } from "@/hooks/ads-refresh";
import request from "@/apis/request";
import { API_PUBLIC_HOST, TERMCONDITION } from "@/_constant/urlconstants";
import axios from "axios";
import MainAdsLayout from "@/_component/MainAdsLayout";
import PageTitle from "@/_component/Common/page/PageTitle";
import Button from "@/_component/Common/button/Button";
import { Link } from "react-router-dom";

const ProfileDetail = () => {
    const [viewUserData, setViewUser] = useState([]);
    const [userAddress, setUserAddress] = useState([]);
    const [countryList, setCountry] = useState(null);
    const [isPrimaryAddress, setIsPrimaryAddress] = useState(false);
    const [tehsilList, setTehsil] = useState(null);
    const [stateList, setState] = useState(null);
    const [districtList, setDistrict] = useState(null);

    const schema = {
        name: '',
        phone: '',
        email: '',
        address1: "",
        countryid: "91",
        villageCity: "",
        stateid: "",
        districtid: "",
        tehsilid: "",
        pincodeid: "",
        houseNoStreetid: "",
        referalcodeId: "",
        gstnumberId: "",
        organizationnameid: "",
        farmnameid: "",
        FathersNameId: "",
        businesstypeid: "",
        serviceid: "",
        studfarmid: "",
        licensenumberid: "",
        ExperienceId: "",
        degreecrftId: "",
        isPrimary: "",
    };
    const [dealerRegisterData, setDealerRegisterData] = useState(schema);

    const handleSubmit = async () => {

        const request = {
            "access": "string",
            "availedFreeAdvertisement": true,
            "email": "string",
            "enabled": true,
            "farmerPoints": "string",
            "gst_no": "string",
            "id": "string",
            "isAdFree": true,
            "name": "string",
            "onboardingPoints": "string",
            "org_name": "string",
            "parentReferralCode": "string",
            "phoneNumber": "string",
            "preferredLanguage": "english",
            "profileUrl": "string",
            "referralCode": "string",
            "referralType": "Self",
            "referralUserId": "string",
            "userId": "string",
            "welcomeEmailSent": "string"
        }

        // const res = await SavePriceSettings(request);

        alert("Profile Updated Successfully !.");
        // if (res) {
        // window.location.reload();
        // }
    }


    const handleChangeLocationWiseDetails = (type, query) => {
        getCurrentSession((success, user, jwtToken) => {

            const url =
                `${API_PUBLIC_HOST}/lookup/location?type=` + type + `&query=` + query;
            axios({
                method: "GET",
                url: url,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: jwtToken,
                },
            }).then((response) => {
                if (type === "COUNTRY") {
                    setCountry(response?.data?.response);
                } else if (type === "STATE") {
                    setState(response?.data?.response);
                } else if (type === "DISTRICT") {
                    setDistrict(response?.data?.response);
                } else if (type === "TEHSIL") {
                    setTehsil(response?.data?.response);
                }
            }).catch((error) => { console.log(error); });
        });
    };

    const handleChangeAddAddressInformation = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name === "countryid") {
            setDealerRegisterData({ ...dealerRegisterData, countryid: value })
            handleChangeLocationWiseDetails("STATE", value);
        } else if (name === "stateid") {
            setDealerRegisterData({ ...dealerRegisterData, stateid: value })
            handleChangeLocationWiseDetails("DISTRICT", value);
        } else if (name === "districtid") {
            setDealerRegisterData({ ...dealerRegisterData, districtid: value })
            handleChangeLocationWiseDetails("TEHSIL", value);
        } else if (name === "tehsilid") {
            setDealerRegisterData({ ...dealerRegisterData, tehsilid: value })
        }
    }

    const requestData = async () => {

        console.log(dealerRegisterData);


        let viewUser = await request({
            url: `api/v1/public/user/viewUser`,
            method: "GET"
        });
        setViewUser(viewUser.response);
        let listAddress = await request({
            url: `api/v1/public/user/listAddress`,
            method: "GET"
        });

        const isPrimaryAddress = listAddress.response.filter(address => address.isPrimary == true)[0];
        setUserAddress(isPrimaryAddress);

        handleChangeLocationWiseDetails("STATE", isPrimaryAddress.country);
        handleChangeLocationWiseDetails("DISTRICT", isPrimaryAddress.state);
        handleChangeLocationWiseDetails("TEHSIL", isPrimaryAddress.district);

        console.log(userAddress, 'checkonw');
    }

    useEffect(() => {
        requestData()
        handleChangeLocationWiseDetails("COUNTRY");

    }, []);

    useAdsRefresh();
    return (
        <div>
            <MainAdsLayout>
                <PageTitle headingPart1={"Welcome To"} headingPart2={"KISAN GATEWAY"}></PageTitle>
                <div className="row"><p className="kg-dark-gray">Please help us to know more about your</p></div>
                <div className="row"><p className="kg-danger text-end">* Required Field</p></div>
                <form onSubmit={handleSubmit} className="mt-4">
                    <div className="row px-3 py-3">
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">Name</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        disabled
                                        type="text"
                                        value={viewUserData && viewUserData.name}
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">Email</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        value={viewUserData && viewUserData.email}
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">Phone</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        disabled
                                        type="text"
                                        value={viewUserData && viewUserData.phoneNumber}
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">GST Number (if any)</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        value={viewUserData && viewUserData.gst_no}
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">Name of the Organization(if any)</label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        value={viewUserData && viewUserData.org_name}
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row kg-bg-light px-3 py-5 rounded-4">
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">
                                        Village / City <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        value={userAddress && userAddress.villageCity}
                                        type="text"
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">House No. &amp; Street: </label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        value={userAddress && userAddress.houseNoStreet}
                                        type="text"
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">
                                        Country <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="col-md-8">
                                    <select className="form-select" name="countryid" id="harvester"
                                        value={userAddress?.country}
                                        disabled={isPrimaryAddress}
                                        onChange={(e) => handleChangeAddAddressInformation(e)}>
                                        <option value="" disabled >Select Country</option>
                                        {countryList?.map((country, i) => (<option key={i} value={country.id}>{country?.name}</option>))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="from-label">State</label>
                                </div>
                                <div className="col-md-8">
                                    <select className="form-select" id="stateid"
                                        name="stateid"
                                        value={userAddress?.state}
                                        onChange={(e) => handleChangeAddAddressInformation(e)}
                                        disabled={isPrimaryAddress}
                                    >
                                        <option value="" disabled >Select State</option>
                                        {stateList?.map((state, i) => (
                                            <option key={i} value={state.id}>
                                                {state?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">
                                        District <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="col-md-8">
                                    <select className="form-select" id="districtid"
                                        name="districtid"
                                        value={userAddress?.district}

                                        onChange={(e) => handleChangeAddAddressInformation(e)}
                                        disabled={isPrimaryAddress}

                                    >
                                        <option value="" disabled>Select State</option>
                                        {districtList?.map((state, i) => (<option key={i} value={state.id}>{state?.name}</option>))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">
                                        Tehsil <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="col-md-8">
                                    <select className="form-select" id="tehsilid" name="tehsilid"
                                        value={userAddress?.tehsil}

                                        onChange={(e) => handleChangeAddAddressInformation(e)}
                                        disabled={isPrimaryAddress}
                                    >
                                        <option value="" disabled>Select tehsil</option>
                                        {tehsilList?.map((state, i) => (
                                            <option key={i} value={state.id}>
                                                {state?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">Pincode </label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        value={userAddress && userAddress.pincode}
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">Referral Code </label>
                                </div>
                                <div className="col-md-4 mb-md-0 mb-3">
                                    <div className="d-inline-flex gap-3">
                                        <div className="form-check ps-0">
                                            <div><label htmlFor="self" className="kg-dark-gray me-1">Self</label></div>
                                            <input type="radio" name="referral" id="self" />
                                        </div>
                                        <div className="form-check ps-0">
                                            <div><label htmlFor="friend" className="kg-dark-gray me-1">Friend</label></div>
                                            <input
                                                type="radio"
                                                name="referral"
                                                id="friend"
                                                defaultChecked=""
                                            />
                                        </div>
                                        <div className="form-check ps-0">
                                            <div><label htmlFor="agent" className="kg-dark-gray me-1">Agent</label></div>
                                            <input
                                                type="radio"
                                                name="referral"
                                                id="agent"
                                                defaultChecked=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="modal"
                                        value={viewUserData && viewUserData.referralCode}
                                        placeholder="Referral Code"
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">
                                        Password <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="row p-0">
                                <div className="col-md-4">
                                    <label className="form-label">
                                        Confirm Password <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="modal"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-check col-md-12 py-4">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="defaultCheck1"
                            />
                            <label className="form-check-label ms-2" htmlFor="defaultCheck1">
                                I agree to all <Link className="kg-link kg-green" to={TERMCONDITION}><b>Terms and conditions</b></Link>
                            </label>
                        </div>
                        <div className="col-12 text-center">
                            <Button warning solid rounded type="submit" className="px-5"><span className="px-5 fs-5">Save</span></Button>
                        </div>
                    </div>
                </form>
            </MainAdsLayout>
            <OfferZone />
        </div>
    );
};

export default ProfileDetail;