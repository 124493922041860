import React from "react";
import { Outlet } from "react-router";
import { useAdsRefresh } from "../../hooks/ads-refresh";
import KgTabs from "../Common/tabs/kg-tabs";
import OfferZone from "../public/plugins/OfferZone/OfferZone";
import TopBanner from "../Common/ads/topbanner/TopBanner";
import BottomBanner from "../Common/ads/bottombanner/BottomBanner";

const BaseListing = () => {
    const listingMenus = [
        {
            name: "General",
            link: "/listings",
            is_active: true,
            icon: ''
        },
        {
            name: "Dealers",
            link: "/listings/Dealer",
            is_active: false,
            icon: ''
        },
        {
            name: "Service Providers",
            link: "/listings/serviceproviderlist",
            is_active: false,
            icon: ''
        },
        {
            name: "Offer Zone",
            link: "/listings/OfferZoneListings",
            is_active: false,
            icon: ''
        },
        {
            name: "Adzone",
            link: "/listings/AdZoneListings",
            is_active: false,
            icon: ''
        },
        {
            name: "More",
            link: "/listings/more",
            is_active: false,
            icon: ''
        },

    ];

    useAdsRefresh();

    return (
        <>
            <main className="main_content">
                <TopBanner />
                <Outlet />
                <BottomBanner />
                <OfferZone />
            </main>
        </>
    )
};

export default BaseListing;