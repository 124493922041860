import {getCurrentSession} from '../../endpoints/amplify/auth';
import { API_PUBLIC_HOST } from '../../_constant/urlconstants';
import $ from 'jquery';

export function getUserDetails() {
  return new Promise((res) => {
    getCurrentSession(function (success, result, jwtToken) {
      if (success) {
        $.ajax({
          type: "GET",
          dataType: "json",
          url: API_PUBLIC_HOST + "/user/viewUser",
          headers: { Authorization: jwtToken },
          success: function (result) {
            res(result);
          },
          error: function (err) {
            console.log("error : ", err);
          },
        });
      } else {
        console.log("something is wrong");
      }
    });
  });
}


export function updateUserDetails(data) {
  return new Promise((res) => {
    getCurrentSession(function (success, result, jwtToken) {
      if (success) {
        $.ajax({
          type: "POST",
          dataType: "json",
          url: API_PUBLIC_HOST + "/user/listUserPlans",
          headers: { Authorization: jwtToken },
          data : data,
          success: function (result) {
            res(result);
          },
          error: function (err) {
            console.log("error : ", err);
          },
        });
      } else {
        console.log("something is wrong");
      }
    });
  });
}