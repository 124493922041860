// import "./transaction.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getCurrentSession } from "@/endpoints/amplify/auth";
import { API_PUBLIC_HOST } from "@/_constant/urlconstants";
import { useParams } from 'react-router-dom';
import MainAdsLayout from "@/_component/MainAdsLayout";
import PageTitle from "@/_component/Common/page/PageTitle";
import Button from "@/_component/Common/button/Button";
import { formatFromISODate } from "@/utility/common-utils";

const TransactionDetails = ({downloadInvoice}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const { id } = useParams();

  // Fetch General Plan Data
  const getTransactionList = (jwtToken, user) => {
    axios({
      method: "get",
      url: `${API_PUBLIC_HOST}/payment-transactions/my-transactions/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    }).then((response) => {
      setTransaction(response?.data?.response);
    })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getCurrentSession((success, user, jwtToken) => {
      setIsLoading(true);
      getTransactionList(jwtToken, user);
    });
    setIsLoading(true);
  }, []);

  return (
    <MainAdsLayout sideBanner>
      <PageTitle headingPart1={"Transaction"} headingPart2={"Details"}>
      </PageTitle>
      <div className="pe-md-3">
        <div className="row pb-2">
          <div className="col">
            <div>Dealer Type Transaction</div>
          </div>
        </div>

        <div>
          <div className="card rounded-4 border-0 mb-4 kg-bg-light">
            <div className="row border border-secondary rounded-4 g-0 kg-bg-white p-3">
              <div className="col-md-6 col-8 mb-2 mb-md-0">
                <div>
                  <span className="kg-green mb-2 fw-bold">{transaction && transaction.categoryName}</span>
                  <span className="mx-2">-</span>
                  <span>{transaction && transaction.description}</span>
                </div>
                <span><b>{transaction && formatFromISODate(transaction.paymentDate, 'dd, MMM yyyy hh:mm a')}</b></span>
              </div>
              <div className="col-md-2 col-4 mb-2 mb-md-0 text-md-center">
                <div className="kg-green mb-2"><small>Amount</small></div>
                <p><b>₹ {transaction && transaction.subtotal}</b></p>
              </div>
              <div className="col-md-2 col-8 text-md-center">
                <div className="kg-green mb-2"><small>GST</small></div>
                <p><b>₹ {transaction && transaction.gstAmount}</b></p>
              </div>
              <div className="col-md-2 col-4 text-md-center">
                <div className="kg-green mb-2"><small>Total Amount</small></div>
                <p><b>₹ {transaction && transaction.total}</b></p>
              </div>
              <div className="row mt-2 g-0">
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-8 col-md-10">
                  <div className="kg-green mb-2"><small>Transaction Id</small></div>
                  <p><b>{transaction.transactionId}</b></p>
                </div>
                <div className="col-4 col-md-2 text-md-center">
                  <div className="kg-green mb-2"><small>Invoice</small></div>
                  <Button primary noSpacing onClick={() => downloadInvoice(transaction && transaction.transactionId)}>
                  <i className="bi bi-cloud-arrow-down-fill me-1" /><span>DOWNLOAD</span>
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="row g-0 px-3 pt-3">
                <div className="col-4"><div className="kg-dark-gray"><small>Item Id</small></div></div>
                <div className="col-6"><div className="kg-dark-gray"><small>Item Name</small></div></div>
                <div className="col-2 text-md-center"><div className="kg-dark-gray"><small>Amount</small></div></div>
              </div>
              {transaction.transactionDetails && transaction.transactionDetails.map((r, i) => {
                return (
                  <div key={i} className="row g-0 px-3 pb-3">
                    <div className="col-4">
                      <p><b>{r && r.itemId}</b></p>
                    </div>
                    <div className="col-6">
                      <p><b>{r && r.itemName}</b></p>
                    </div>
                    <div className="col-2 text-md-center">
                      <p><b>₹ {r && r.total}</b></p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </MainAdsLayout>
  );
}

export default TransactionDetails;
