export default function PageTitle({ children, headingPart1, headingPart2 }) {
    return (
        <div className="row mb-5">
            <div className="d-flex flex-column flex-md-row align-items-md-center gap-2">
                <div className="flex-md-fill">
                    <h3><span className="me-1">{headingPart1}</span><span className="fs-1 text-bold">{headingPart2}</span></h3>
                </div>
                {children}
            </div>
        </div>
    );
}