import { S3_HOST } from "./urlconstants";
import register_icon from "@/assets/images/new/register-icon.svg";
import news_img from "@/assets/images/new/ad-news.png";
import events_img from "@/assets/images/new/ad-events.png";
import build_ads_img from "@/assets/images/new/add-board.png";
import sell_service_img from "@/assets/images/new/sell-services.svg";
import buy_service_img from "@/assets/images/new/purchase.svg";
import service_img from "@/assets/images/new/services.svg";
import offer_service_img from "@/assets/images/new/offer-zone.svg";
import adzone_service_img from "@/assets/images/new/ad-zone.svg";
import about_us_img from "@/assets/images/about_right.png";
import profileImg from "@/assets/images/profile.jpg";
import leftArrowIcon from "@/assets/images/new/left-arrow.svg";
import rightArrowIcon from "@/assets/images/new/right-arrow.svg";
import shareIcon from "@/assets/images/new/share-icon.svg";

export const COMPANY_LOGO = S3_HOST + "/images/logos/company-logo.png";
export const APP_STORE = S3_HOST + "/images/icons/app-store-download-app.png";
export const PLAY_STORE = S3_HOST + "/images/icons/play-store-download-app.png";
export const NO_IMAGE = S3_HOST + "/images/icons/no-image.png";

export const PROFILE_PLACEHOLDER_IMG = profileImg;

export const NEWS_IMG = news_img
export const EVENTS_IMG = events_img;
export const BUILD_ADS_IMG = build_ads_img;
export const SELL_SERVICE_IMG = sell_service_img;
export const BUY_SERVICE_IMG = buy_service_img;
export const SERVICE_IMG = service_img;
export const OFFER_SERVICE_IMG = offer_service_img;
export const ADZONE_SERVICE_IMG = adzone_service_img;
export const ABOUT_US_IMG = about_us_img;

export const REGISTER_ICON = register_icon;
export const LEFT_ARROW_ICON = leftArrowIcon;
export const RIGHT_ARROW_ICON = rightArrowIcon;
export const SHARE_ICON = shareIcon;

export const GD_MACHINERY = S3_HOST + "/deal-banner/gd-machinery.png";
export const GD_TYRES = S3_HOST + "/deal-banner/gd-tyres.png";