
import React from 'react';
import { Helmet } from 'react-helmet';
import { COMPANY_NAME } from './_constant/appConstant';

function MetaTags({ title = COMPANY_NAME, description = "" }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}

export default MetaTags;
