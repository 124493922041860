import React, {useEffect, useState } from 'react'
import $ from 'jquery';
import {signIn,changePassword_initiate} from '../../../../endpoints/amplify/auth';
import { Error } from '../../../../_service/notification/notification';
import { useLocation, useNavigate } from "react-router-dom";
import {setLoginPopup} from "../../../../store/reducer/loginSlice/loginSlice";
import { useDispatch } from "react-redux";
import { S3_HOST } from '../../../../_constant/urlconstants';
import { useLanguageContext } from '../../../../hooks/context-hooks';

export default function Login({handleChangeLogin,handleChangeRegistrationspage ,show}) {
  const { language } = useLanguageContext();

const url = `${S3_HOST}/country/countrycodes.json`;
const [countryCode , setCountryCode]  = useState(91);
const [countryList , setCountryList]  = useState([]); 
const { state } = useLocation();
let navigate = useNavigate()
const dispatch = useDispatch();

useEffect(() => {
  $("#login_error").hide();
  const fetchData = async () => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setCountryList(data);
    } catch (error) {
      console.error('Error - ', error);
    }
  };
  fetchData();
}, []);

const signUserIn=(event) =>{
  event.preventDefault();
  const countryCode = '+' + $('#countryCodeId_login :selected').val();
  const username = $('#login_username').val();
  const password = $('#login_password').val();
  if (password === "" || username.length < 5 || username === "") {
    $("#login_error").show();
    $("#login_error").text("Please fill all the fields to register.");
    $(event.target).attr("disabled", false);
  } else {
    signIn(countryCode + username, password, "", event);
  }
  
}

function isValidMobileNumber(mobileNumber) {
  const regex = /^[0-9]{10}$/;
  const cleanedNumber = mobileNumber.replace(/\D/g, '');
  if(!regex.test(cleanedNumber)){
    $("#login_error").show();
    $("#login_error").text("Please provide valid phone number");
  } else {
    $("#login_error").hide();
  }
}

const forgetPassword = (e) => {
  const username =  $('#login_username').val();
  if(username != ""){
    changePassword_initiate("+91"+$('#login_username').val(), handleChangePassword)
  } else {
      Error('Please enter a username');
  }
}

const handleChangePassword =(type , user)=>{
  if(type){
     const item = {
       username : user,
       route : 'password',
       phoneNumber:"+91"+$('#login_username').val(),
     }
     dispatch(setLoginPopup(false));
     navigate("/resetpassword", {replace: true,state:JSON.stringify(item)})
  }
}

  return (
    <div className={`modal fade login_popup ${show}`}  id="login_popup" style={{"display":"block"}}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="close_modal">
            <span  onClick={(e)=>handleChangeLogin(e)} className="btn-close" style={{"fontSize":"15px"}}></span>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close" onClick={handleChangeLogin} > x</button> */}
            </div>
            <div className="modal-body">
              <div className="login_popup_main">
                <div className="login_inner">
                  <h3 lang="en">LOGIN</h3>
                  <div className="mb-3"  style={{display:'inline-flex'}}>
                    <select value={countryCode} name="countryCode" id="countryCodeId_login" className="form-control" readOnly 
                      style={{ width: '58px', borderRadius: '15px 2px 2px 15px', important: 'true', padding:'12px', height:'52px' }}>
                     {countryList.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option?.display?.replace(/&nbsp;/g, ' ')}
                         
                        </option>
                      ))}
                    </select>
                   <input id="login_username" type="number" className="form-control"  style={{borderRadius: '2px 15px 15px 2px', important: 'true' }} placeholder={language("label.enter_phone_number")}/>
                  </div>
                  <div className="mb-3">
                    <input
                      id="login_password"
                      type="password"
                      className="form-control"
                      placeholder={language("label.enter_you_login_password")}
                    />
                  </div>

                  <button
                    id="loginbuttonid"
                    lang="en"
                    type="submit"
                    className=""
                    onClick={(e)=>signUserIn(e)}
                  >
                    {language("label.login")}
                  </button>
                  <div id="login_error" lang="en" className="alert_m"></div>
                  <div
                    className="text-right forgot_pass"
                    data-bs-dismiss="modal"
                  >
                   <span  onClick={(e)=>forgetPassword(e)}> <a lang="en">
                    
                    {language("label.forgot_password")}
                    </a></span>
                  </div>
                </div>
                <button id="modalcloseid" data-bs-dismiss="modal"></button>
                <div className="bottom_login_part">
                  <p lang="en">
                  {language("If_you_are_not_a_member")}
                    
                    </p>
                  <div className="text-center">
                    <button
                      lang="en"
                      data-bs-dismiss="modal"
                      className="register_modal_btn"
                      onClick={(e)=>{handleChangeRegistrationspage(e); }}
                    >
                      {language("label.register")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

