import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {loginSlice} from './reducer/loginSlice/loginSlice';
import {userSlice} from './reducer/userSlice/userSlice';
import {languageSlice} from './reducer/languageSlice/languageSlice';
import {categoriesListSlice} from './reducer/categoriesListSlice/categories';
import { offerZoneSlice } from "./reducer/offerZoneSlice/offerZoneSlice";


export const store = configureStore({
  reducer: {
    [loginSlice.name]: loginSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [languageSlice.name]: languageSlice.reducer,
    [categoriesListSlice.name]: categoriesListSlice.reducer,
    [offerZoneSlice.name]: offerZoneSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>getDefaultMiddleware({serializableCheck:false}).concat()
})
setupListeners(store.dispatch)
