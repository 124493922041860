import React from 'react';
import classNames from 'classnames';
import PropTypes from "prop-types";

Button.propTypes = {
    validateVariationProps: ({ primary, secondary, success, warning, danger }) => {
        const count = Number(!!primary) + Number(!!secondary) + Number(!!success) + Number(!!warning) + Number(!!danger);
        if (count > 1) {
            return new Error("Only one of primary, secondary, success, warning or danger can be true")
        }
    },
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    success: PropTypes.bool,
    warning: PropTypes.bool,
    danger: PropTypes.bool,
    outline: PropTypes.bool,
    rounded: PropTypes.bool,
}

function Button({
    children,
    noSpacing,
    solid,
    primary,
    secondary,
    success,
    warning,
    danger,
    white,
    outline,
    rounded,
    small,
    type = "button",
    ...btnProps
}) {

    const classes = classNames(btnProps.className, 'btn kg-btn d-flex-inline align-items-center', {
        'p-0': noSpacing,
        'py-2 px-3': !noSpacing,
        'kg-primary': primary && !solid,
        'kg-secondary': secondary && !solid,
        'kg-success': success && !solid,
        'kg-warning': warning && !solid,
        'kg-danger': danger && !solid,
        'kg-white': white && !solid,
        'kg-bg-primary kg-white': primary && solid,
        'kg-bg-secondary kg-white': secondary && solid,
        'kg-bg-success kg-white': success && solid,
        'kg-bg-warning kg-white': warning && solid,
        'kg-bg-danger kg-white': danger && solid,
        'rounded-pill': rounded,
        'kg-bg-white': outline,
        'kg-primary border kg-border-primary': outline && primary,
        'kg-secondary border kg-border-secondary': outline && secondary,
        'kg-success border kg-border-success': outline && success,
        'kg-warning border kg-border-warning': outline && warning,
        'kg-danger border kg-border-danger': outline && danger,
        'btn-sm': small
    })

    return (
        <button {...btnProps} type={type} className={classes}>
            {children}
        </button>
    );
}

export default Button;