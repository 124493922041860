import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const languageSlice = createSlice({
  name: "languageSlice",
  initialState,
  reducers: {
    setLanguageData: (state, action) => {
      state.data = action.payload;
    },
  },
});
export const { actions, reducer } = languageSlice;
export const { setLanguageData } = actions;
export const getLanguageData = (state) => {
  return state.languageSlice.data;
};
export default reducer;
