import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import './Footer.css';
import { APP_STORE as APP_STORE_IMG, PLAY_STORE as PLAY_STORE_IMG, COMPANY_LOGO } from '@/_constant/image-url-constants';
import { setLoginPopup, } from "@/store/reducer/loginSlice/loginSlice";
import { getUserData } from "@/store/reducer/userSlice/userSlice";
import { getSellCategoriesData } from "@/store/reducer/categoriesListSlice/categories";
import { FACEBOOK, INSTAGRAM, PLAY_STORE, APP_STORE, TWITTER, YOUTUBE, TERMCONDITION, PRIVACYPOLICY, REFUNDPOLiCY, ADSPOLICY, ABOUT_US, CONTACT_US } from "@/_constant/urlconstants";
import { COMPANY_ADDRESS, COMPANY_EMAIL, COMPANY_PHONE } from '@/_constant/appConstant';
import { useLanguageContext } from '@/hooks/context-hooks';

export default function Footer() {
  const { language } = useLanguageContext();
  const navigate = useNavigate();
  
  const sellCategoriesList = useSelector(getSellCategoriesData);
  const showSellCategoriesBuy = [1, 182, 97, 106, 125, 113, 170, 126, 166, 139, 79, 175];
  const userDetails = useSelector(getUserData);
  const dispatch = useDispatch();

  const handleMenuTabs = (e, name) => {
    e.preventDefault();
    if (Object.keys(userDetails).length > 0) {
      navigate("/" + name, { replace: true });
    } else {
      dispatch(setLoginPopup(true));
    }
  };

  const homePage = () => {
    navigate("/", { replace: true });
  };

  const openCategorySellBuy = (cat, id) => {
    let temp = sellCategoriesList?.find((item) => item.id === id);
    if (cat === "sell") {
      let g = btoa("gen");
      const itemData = JSON.stringify({ UserPlanId: temp?.user_plan_id, category_id: id, name: temp?.name, editType: 'general' });
      navigate(`/sell/${encodeURIComponent((temp?.name || '').replace(/\s+/g, '-').replace(/\//g, '-').replace(/[^\w\s-]/g, ''))}?id=${encodeURIComponent(temp?.id || '') + "&@=" + encodeURIComponent(g || '')}`, { state: { item: itemData } });
    } else if (cat === "buy") {
      const itemData = JSON.stringify({ user_plan_id: temp?.user_plan_id, category_id: id, name: temp?.name, type: 'search' });
      navigate(`/buy/${encodeURIComponent(temp?.name.replace(/\s+/g, '-').replace(/\//g, '-').replace(/[^\w\s-]/g, ''))}?${encodeURIComponent(temp?.id)}`, { state: { item: itemData } });
    } else {
      const itemData = JSON.stringify({ user_plan_id: temp?.user_plan_id, category_id: id, name: temp?.name, type: 'search' });
      navigate(`/services/${encodeURIComponent(temp?.name.replace(/\s+/g, '-').replace(/\//g, '-').replace(/[^\w\s-]/g, ''))}?${encodeURIComponent(temp?.id)}`, { state: { item: itemData } });
    }
  };

  return (
    <footer className="container-fluid p-0 bg-light border-top">
      <section className="container py-5 px-2">
        <div className="row gap-3 gap-md-0 text-sm-start text-center">
          <div className="col-lg-3 col-md-6">
            <div className="mb-1">
              <img src={COMPANY_LOGO} alt="Kisan Gateway Logo" />
            </div>
            <div className="download-app">
              <p className="py-2">
                Download the Kisan Gateway app & enjoy features
              </p>
              <div className="mb-2">
                <a href={PLAY_STORE}><img className="dowload-app-icon" src={PLAY_STORE_IMG} alt="Download app from google play store" /></a>
              </div>
              <div>
                <a href={APP_STORE}><img className="dowload-app-icon" src={APP_STORE_IMG} alt="Download app from app store" /></a>
              </div>
            </div>
            <div>
              <h5 className="pt-3 kg-title-ul">
                Follow Us
              </h5>
              <div className="d-flex flex-wrap gap-3 justify-content-center justify-content-sm-start py-2">
                <a href={INSTAGRAM}>
                  <i className="bi fs-2 bi-instagram social-icon instagram"></i>
                </a>
                <a href={TWITTER}>
                  <i className="bi fs-2 bi-twitter social-icon twitter"></i>
                </a>
                <a href={YOUTUBE}>
                  <i className="bi fs-2 bi-youtube social-icon youtube"></i>
                </a>
                <a href={FACEBOOK}>
                  <i className="bi fs-2 bi-facebook social-icon facebook"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="pt-3 kg-title-ul">Pages</h5>
            <div className="d-flex flex-column gap-3">
              <a className="link-animate-ul" onClick={() => homePage()}>{language("label.home")} </a>
              <Link to={ABOUT_US} className="link-animate-ul">{language("label.about_us")} </Link>
              <Link to={CONTACT_US} className="link-animate-ul">{language("label.contact_us")}</Link>
              <a className="link-animate-ul" onClick={(e) => { handleMenuTabs(e, "listings") }}>{language("label.my_listing")}</a>
              <a className="link-animate-ul" onClick={(e) => { handleMenuTabs(e, "PaymentSummary") }}>{language("label.my_payment")}</a>
              <a className="link-animate-ul" onClick={(e) => { handleMenuTabs(e, "Postings") }}>{language("label.my_posting")}</a>
              <a className="link-animate-ul" onClick={(e) => { handleMenuTabs(e, "offerzone") }}>{language("label.offer_zone")}</a>
              <a className="link-animate-ul" onClick={(e) => { handleMenuTabs(e, "adzone") }}>{language("label.ad_zone")}</a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="kg-title-ul pt-3">Categories</h5>
            <div className="d-flex flex-column gap-3">
              {!!sellCategoriesList && sellCategoriesList?.map((item, i) => (
                showSellCategoriesBuy.includes(+item?.id) && i < 7 && item?.is_active &&
                <a className="link link-animate-ul" key={i} onClick={() => openCategorySellBuy("buy", item?.id)}>{language(item?.displayCode)}</a>
              ))}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="kg-title-ul pt-3">Get in touch</h5>
            <div className="address-sec">
              <address>{COMPANY_ADDRESS}</address>
              <div><i className="bi bi-telephone-fill fs-5 kg-green me-2 mb-3" aria-hidden="true"></i><a className="" href={`tel:${COMPANY_PHONE}`}>{COMPANY_PHONE}</a></div>
              <div><i className="bi bi-envelope-fill fs-5 kg-green me-2" aria-hidden="true"></i><a className="" href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a></div>
            </div>
            <div className="subscribe">
              <h5 className="kg-title-ul py-3">Subscribe</h5>
              <div className="input-group">
                <input type="text" className="form-control mb-0" placeholder="Your Email" />
                <button className="btn kg-btn kg-btn-success">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="kg-bg-gray">
        <div className="container">
          <div className="row py-4">
            <div className="col-12 py-1">
              <div className='d-flex flex-lg-nowrap flex-wrap justify-content-center gap-lg-0 gap-3'>
                <div className="copy-right flex-grow-1 text-center text-lg-start">
                  <span>2021 - {new Date().getFullYear()} © Kisan Gateway. All Rights Reserved.</span>
                </div>
                <div className="terms-policy">
                  <div className="d-flex justify-content-center">
                    <Link to={TERMCONDITION} className="text-center border-1 border-dark border-end pe-md-2">Terms And Conditions</Link>
                    <Link to={PRIVACYPOLICY} className="text-center border-1 border-dark border-end px-md-2">Privacy Policy</Link>
                    <Link to={REFUNDPOLiCY} className="text-center border-1 border-dark border-end px-md-2">Refund Policy</Link>
                    <Link to={ADSPOLICY} className="text-center ps-md-2">Ads Policy</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}
