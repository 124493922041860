import React from "react";
import { Outlet } from "react-router";
import { useAdsRefresh } from "../../hooks/ads-refresh";
const BaseCreatePost = () => {
    
    useAdsRefresh();

    return (
        <>
            <Outlet />
        </>
    )
};

export default BaseCreatePost;