export const API_HOST = process.env.REACT_APP_API_HOST;
export const S3_HOST = process.env.REACT_APP_S3_PUBLIC_HOST;
export const API_PUBLIC_HOST = API_HOST + "/api/v1/public";
export const API_PRIVATE_HOST = API_HOST + "/api/v1/private";

export const PLAY_STORE = "https://play.google.com/store/apps/details?id=com.kisangateway&pcampaignid=web_share";
export const APP_STORE = "https://apps.apple.com/in/app/kisan-gateway/id6474902759";
export const INSTAGRAM = "https://www.instagram.com/kisangateway";
export const TWITTER = "https://twitter.com/GatewayKisan";
export const YOUTUBE = "https://www.youtube.com/@kisangateway";
export const FACEBOOK = "https://www.facebook.com/Kisan-Gateway-101573472131388";

/**********************Export Routes */
export const CATEGORIESLIST = "/categoriesList/:slug";
export const ADDRESS = "/address";
export const UPLOADPHOTOS = "/uploadphotos";
export const PREVIEWLISTINGPAGE = "/PreviewListingPage";
export const PREVIEWPOSTINGPAGE = "/PreviewPostingPage";

export const EDITLISTINGTRACTOR = "/EditListingTractor";
export const EDITEDADDRESS = "/EditedAddress";
export const SERVICELIST = "/services";

export const PREFIX_LISTING = "/listings";
export const GENERAL_LISTING = PREFIX_LISTING + "";
export const ADDZONELIST = PREFIX_LISTING + "/AdZoneListings";
export const DEALER_LISTING = PREFIX_LISTING + "/Dealer";
export const SERVICEPROVIDERLIST = PREFIX_LISTING + "/serviceproviderlist";
export const OFFERZONELISTINGS = PREFIX_LISTING + "/OfferZoneListings";

export const PAYMENTSUMMARY = "/PaymentSummary";

export const PREFIX_POSTING = "/postings";
export const MYPOSTINGS = PREFIX_POSTING + "";
export const DEALERPOSTINGS = PREFIX_POSTING + "/DealerPostings";
export const SERVICEPROVIDERPOSTING = PREFIX_POSTING + "/ServiceProviderPostings";
export const OFFERZONEPOSTING = PREFIX_POSTING + "/OfferZoneMyPostings";
export const ADZONE_POSTING = PREFIX_POSTING + '/AdZoneMyPostings';
export const BUYLISTING = "/BuyListings";

/******************* create post routing*******************/
export const PREFIX = "/post/create_post_";
export const PREFIX_CREATE_POST = PREFIX;
export const MACHINERYREAPER = PREFIX_CREATE_POST + "reaper";
export const MACHINERYBALERS = PREFIX_CREATE_POST + "Balers";
export const TRACTOR = PREFIX_CREATE_POST + "tractor";
export const HARVESTER = PREFIX_CREATE_POST + "harvester";
export const FI = PREFIX_CREATE_POST + "FI";
export const TRACTORTRADER = PREFIX_CREATE_POST + "tractor_trader";
export const HARVESTERTRADERS = PREFIX_CREATE_POST + "harvester_traders";
export const FITRADERS = PREFIX_CREATE_POST + "FI_traders";
export const REAPERTRADER = PREFIX_CREATE_POST + "reaper_trader";
export const BALERSTRADER = PREFIX_CREATE_POST + "Balers_trader";
export const TYRE = PREFIX_CREATE_POST + "tyre";
export const CROPREG = PREFIX_CREATE_POST + "crop_reg";
export const BUFFALO = PREFIX_CREATE_POST + "buffalo";
export const CAMEL = PREFIX_CREATE_POST + "camel";
export const COW = PREFIX_CREATE_POST + "cow";
export const DOG = PREFIX_CREATE_POST + "dog";
export const GOAT = PREFIX_CREATE_POST + "Goat";
export const OX = PREFIX_CREATE_POST + "ox";
export const SHEEP = PREFIX_CREATE_POST + "sheep";
export const SILAGE = PREFIX_CREATE_POST + "silage";
export const HORSETRADERS = PREFIX_CREATE_POST + "horse_traders";
export const BIOMASSTRADERS = PREFIX_CREATE_POST + "biomass_trader";
export const FRUITSVEGREG = PREFIX_CREATE_POST + "fruits_veg_reg";
export const PROPERTYINFORMATIONFORM = PREFIX_CREATE_POST + "property";
export const PFS = PREFIX_CREATE_POST + "PFS";
export const DOMESTIC = PREFIX_CREATE_POST + "domestic_pumpmotor";
export const AGRICULTURE = PREFIX_CREATE_POST + "agriculture_pumpmotor";
export const INDUSTRIAL = PREFIX_CREATE_POST + "industrial_pumpmotor";
export const LIVESTOCKFEED = PREFIX_CREATE_POST + "livestock_feed";
export const MACHINERYSERVICE = PREFIX_CREATE_POST + "machinery_service";
export const BOREWELL = PREFIX_CREATE_POST + "boreWell";
export const BANKSERVICE = PREFIX_CREATE_POST + "bankServices";
export const INSURENCESERVICE = PREFIX_CREATE_POST + "insurenceService";
export const WELDINGFAB = PREFIX_CREATE_POST + "welding_fab";
export const WATERPUMP = PREFIX_CREATE_POST + "motor_water_pumpRepair";
export const HOURSEDOGTRANAIR = PREFIX_CREATE_POST + "horseDogTrainer";
export const ARTIFICIALLIVESTOCKBREEDING = PREFIX_CREATE_POST + "artificial_live_stock_breeding";
export const NATURALLIVESTOCKBREEDING = PREFIX_CREATE_POST + "natural_live_stock_breeding";
export const ARTIFICIALHORSEBREEDING = PREFIX_CREATE_POST + "artificial_horse_breeding";
export const NATURALHORSEBREEDING = PREFIX_CREATE_POST + "natural_horse_breeding";
export const WATERSOILTESTMANURE = PREFIX_CREATE_POST + "manure_water_testing";
export const SOILTEST = PREFIX_CREATE_POST + "soil_testing";
// export const NATURALHORSEBREEDING = PREFIX_CREATE_POST+'natural_horse_breeding';

const AGENT_PREFIX = "/agent";
export const AGENT_BANK_DETAILS = AGENT_PREFIX + "/bank-details";
export const AGENT_CLUSTER = AGENT_PREFIX + "/mis-refferals";
export const AGENT_MIS_REPORT = AGENT_PREFIX + "/mis-report"

export const DEALERBUYPRESEARCH = "/dealer_buy_preSearch";
export const SEARCHRESULTPAGE = "/search_result";
export const GREATDEALS = "/great_deals";
export const VERIFYREGISTER = "/verify_register";
export const REGISTERAS = "/register_as";
export const SERVICEPROVIDER = "/serviceprovider";
export const DEALERPROVIDER = "/dealerprovider";
export const AGENTPROVIDER = "/agentprovider";
export const FARMERPROVIDER = "/farmerprovider";
export const OTHERSPROVIDER = "/otherprovider";
export const CATEGORIESITEM = "/categoryitem";


export const PRIVACYPOLICY = "/privacypolicy";
export const TERMCONDITION = "/termcondition";
export const REFUNDPOLiCY = "/refundpolicy";
export const ADSPOLICY = "/adspolicy";
export const CONTACT_US = "/contactus";
export const ABOUT_US = "/aboutus";
export const MY_TRANSACTION = "/my-transactions";
export const MY_ADDRESS = "/my-address";
export const MY_PROFILE = "/my-profile";
export const PROFILE_DETAILS = "/profile-details";
export const MY_LEADS = "/MyLeads";
export const MY_DEALERSHIP = "/my-dealership";
export const CHANGE_PASSWORD = "/change-password";

export const DEALERONBOARDFROM = "/dealeronboardfrom";

export const AGENTREG = "/agentreg";
export const OTHERSREG = "/otherreg";
export const FARMERREG = "/farmerreg";
export const SERVICESREG = "/servicesreg";

export const DEALERPLANSLIST = "/delarsitplanslist";
export const ADZONE = "/adzone";

export const OFFERZONE = "/offerzone";
export const OFFERRESULTS = "/OfferResults";
export const OFFERINFORMATION = PREFIX_CREATE_POST + "OfferInformation";
