import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favoriteCategories: [],
  activeCategories: [],
  userAccess: [],
};

export const offerZoneSlice = createSlice({
  name: "offerZoneSlice",
  initialState,
  reducers: {
    setFavoriteCategories: (state, action) => {
      state.favoriteCategories = action.payload;
    },
    setActiveCategories: (state, action) => {
      state.activeCategories = action.payload;
    },
    setUserAcess: (state, action) => {
      state.userAccess = action.payload;
    },
  },
});
export const { actions, reducer } = offerZoneSlice;
export const { setFavoriteCategories, setActiveCategories, setUserAcess } =
  actions;
export const getCategoryInfo = (state) => {
  return {
    activeCategories: state.offerZoneSlice.activeCategories,
    favoriteCategories: state.offerZoneSlice.favoriteCategories,
    userAccess: state.offerZoneSlice.userAccess,
  };
};
export default reducer;
