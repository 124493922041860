export const COMPANY_NAME = "Kisan Gateway LLP";
export const COMPANY_ADDRESS = "No.253/6 & 7, Vasanthapura main Road, Near BRV school, Bangalore 560078";
export const COMPANY_PHONE="+91 9513400800";
export const COMPANY_EMAIL="support@kisangateway.com";
export const ADSERVER_ID = process.env.REACT_APP_ADSERVER_ID;

export  const machineryTraderTyreConditionList = [
  {"value": "0-25%", "name": "0-25%"},
  {"value": "26-50%", "name": "26-50%"},
  {"value": "51-75%", "name": "51-75%"},
  {"value": "76-100%", "name": "76-100%"}
];

export const clutchTypeList = [
  {"value": "Single", "name": "Single"},
  {"value": "Dual", "name": "Dual"}
];

export const wheelDrive = [
  {"value": "2 wheel", "name": "2 wheel"},
  {"value": "4 wheel", "name": "4 wheel"}
];

export const typeOfReaper = [
  {"value": "Self propelled", "name": "Self propelled"},
  {"value": "Tractor mounted", "name": "Tractor mounted"}
];

export const cropsList =[
{value:"MUSTARD", name:"MUSTARD"},
{value:"SUNFLOWER",name:"SUNFLOWER"},
{value:"OIL SEEDS",name:"OIL SEEDS"},
{value:"SUGARCANE",name:"SUGARCANE"},
{value:"JOWAR",name:"JOWAR"},
{value:"BAJRA",name:"BAJRA"},
{value:"GRAM",name:"GRAM"},
{value:"CHILI",name:"CHILI"},
{value:"GROUNDNUT",name:"GROUNDNUT"},
{value:"SPICES",name:"SPICES"},
{value:"FLORICULTURE",name:"FLORICULTURE"},
{value:"MEDICINAL/AROMATIC PLANTS" , name:"MEDICINAL/AROMATIC PLANTS"},
{value:"BETEL VINE",name:"BETEL VINE"},
{value:"MUSHROOM",name:"MUSHROOM"},
{value:"HONEY",name:"HONEY"},
{value:"JUTE",name:"JUTE"},
{value:"TUR",name:"TUR"},
{value:"DATES",name:"DATES"},
{value:"SOYBEAN",name:"SOYBEAN"},
{value:"NIGER",name:"NIGER"},
{value:"RAGI",name:"RAGI"},
{value:"REDGRAM",name:"REDGRAM"},
{value:"WALNUTS",name:"WALNUTS"},
{value:"TEA","name":"TEA"},
{value:"COFFEE" , name:"COFFEE"},        
{value:"SILK COCOONS" ,name:"SILK COCOONS"},        
{value:"RUBBER", name:"RUBBER"}
];


export const ConsultationOf =[
  // {"value": "horse_dog_trainer.consultation.Breeding", "name": "Breeding"},
  // {"value": "horse_dog_trainer.consultation.Artificial.Insemination","name": "Artificial Insemination"},
  // {"value": "horse_dog_trainer.consultation.Day.care", "name": "Day care"},
  // {"value": "horse_dog_trainer.consultation.Daily.care.tip", "name": "Daily care tip"}

  {"value": "Breeding", "name": "Breeding"},
  {"value": "Artificial.Insemination","name": "Artificial Insemination"},
  {"value": "Day care", "name": "Day care"},
  {"value": "Daily care tip", "name": "Daily care tip"}
];

export const TwineNet =[
  {"value": "Twine", "displayCode": "label.twine"},
  {"value": "Net", "displayCode": "label.net"},
  {"value": "Twine/Net", "displayCode": "label.twine_net"}
];

export const actersHr =[
  {"value": "acres/hr", "displayCode": "acres/hr"},
  {"value": "tons/hr", "displayCode": "tons/hr"},
  {"value": "bales/hr", "displayCode": "bales/hr"}
];

export const actersRepaerHr =[
  {"value": "acres/hr", "displayCode": "acres/hr"},
  {"value": "tons/hr", "displayCode": "tons/hr"},
  {"value": "trolleys/hr", "displayCode": "trolleys/hr"}
];