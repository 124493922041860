import { Auth } from 'aws-amplify';

export async function getCurrentSession() {
                let token = '';
                token =  await Auth.currentSession().then(data => {
                    let idToken = data.getIdToken();
                    return idToken["jwtToken"]
                });
                if (token) {
                    return { 'Authorization': token };
                } else {
                    return {};
                }
}