import { Outlet } from "react-router";
import { Error } from "@/_service/notification/notification";
import { getCurrentSession } from "@/endpoints/amplify/auth";
import { API_PUBLIC_HOST } from "@/_constant/urlconstants";
import axios from "axios";

export default function BaseTransaction() {
    const downloadInvoice = (id) => {
        getCurrentSession((success, user, jwtToken) => {
            axios.get(`${API_PUBLIC_HOST}/payment-transactions/my-transaction/${id}/generate-invoice?invoice-medium=DOWNLOAD`,
                {
                    "headers": {
                        "Authorization": jwtToken,
                    },
                    "responseType": 'arraybuffer'
                }
            )
                .then((res) => {
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);

                    var link = document.createElement('a');
                    link.href = url;
                    link.download = `Invoice.pdf`;
                    link.dispatchEvent(new MouseEvent('click'));
                })
                .catch((err) => {
                    Error(`Invoice didn't generated for this month`)
                })
        });
    }

    return (
        <Outlet downloadInvoice={downloadInvoice} />
    )
}