import TopBanner from "@/_component/Common/ads/topbanner/TopBanner";
import BottomBanner from "@/_component/Common/ads/bottombanner/BottomBanner";
import RightBanner from "@/_component/Common/ads/rightbanner/RightBanner";
import { useAdsRefresh } from "@/hooks/ads-refresh";

export default function MainAdsLayout({ children, sideBanner = false }) {

    useAdsRefresh();

    function showSideBanner() {
        if (sideBanner) {
            return (
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-fill">
                        {children}
                    </div>
                    <div className="pt-5 pt-lg-0"><RightBanner /></div>
                </div>
            )
        }
        return children;
    }

    return (
        <main>
            <TopBanner />
            <section className="bg-light">
                <div className="container position-relative top-n5 rounded-3 bg-white py-5 px-md-5 px-3">
                    {showSideBanner()}
                </div>
            </section>
            <BottomBanner />
        </main>
    );
}