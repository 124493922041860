import "./profile.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import QRCode from "react-qr-code";
import { API_PUBLIC_HOST, API_HOST, MY_LEADS, MY_ADDRESS, MY_TRANSACTION, CONTACT_US, CHANGE_PASSWORD, MYPOSTINGS, MY_DEALERSHIP, PROFILE_DETAILS } from "@/_constant/urlconstants";
import { getCurrentSession } from "@/endpoints/amplify/auth";
import MainAdsLayout from "@/_component/MainAdsLayout";
import PageTitle from "@/_component/Common/page/PageTitle";
import PageFilter from "@/_component/Common/page/PageFilter";
import { PROFILE_PLACEHOLDER_IMG } from "@/_constant/image-url-constants";

const Profile = () => {
    const PROFILE_OPTIONS = [
        {
            name: "My Leads",
            link: MY_LEADS,
            icon: "fab fa-envira me-4"
        },
        {
            name: "My Transactions",
            link: MY_TRANSACTION,
            icon: "fas fa-credit-card me-4"
        },
        {
            name: "My Address",
            link: MY_ADDRESS,
            icon: "bi bi-geo-alt-fill me-4"
        },
        {
            name: "Support",
            link: CONTACT_US,
            icon: "bi bi-question-circle-fill me-4"
        },
        {
            name: "Change Password",
            link: CHANGE_PASSWORD,
            icon: "bi bi-shield-lock-fill me-4"
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [profilData, setProfilData] = useState([]);
    const [imageUrl, setImageUrl] = useState('')

    const getProfileList = (jwtToken, user) => {

        axios({
            method: "get",
            url: `${API_PUBLIC_HOST}/user/viewUser`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: jwtToken,
            },
        }).then((response) => {
            setProfilData(response?.data?.response);

        })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const file = target.files;

        var files = file ? file[0] : '';
        if (files) {
            var reader = new FileReader();
            var url = URL.createObjectURL(files);
            setImageUrl(url);
        }
    }

    useEffect(() => {
        getCurrentSession((success, user, jwtToken) => {

            setIsLoading(true);
            getProfileList(jwtToken, user);
        });
        setIsLoading(true);
    }, []);

    const handleProfileImageUpload = (e) => {
        e.preventDefault();
    }

    return (

        <MainAdsLayout sideBanner>
            <PageTitle sideBanner headingPart1={'My'} headingPart2={'Profile'}>
                <PageFilter>
                    <Link to={PROFILE_DETAILS} className="kg-btn kg-bg-primary kg-white hover-kg-white rounded-pill py-2 px-3 me-lg-3"><i className="bi bi-pencil-fill me-1" ></i><span>Edit Profile</span></Link>
                </PageFilter>
            </PageTitle>

            <div className="pe-lg-3">
                <div className="card py-3 py-md-4 px-3">
                    <div className="row align-items-center flex-wrap">
                        <div className="col-xl-3 col-lg-6 col-md-3 col-6 mx-auto mb-lg-4 mb-xl-0 mb-4 mb-sm-0 text-center">
                            <div className="position-relative d-inline-block">
                                <img src={profilData?.profileUrl ?? PROFILE_PLACEHOLDER_IMG} className="img-fluid border rounded-circle" style={{height: "7rem", width: "7rem"}} alt="profile-img" />
                                <div className="position-absolute top-0 end-0">
                                    <input
                                        type="file"
                                        onChange={handleProfileImageUpload}
                                        id="profileImageUpload"
                                        className="d-none"
                                        accept=".png, .jpg, .jpeg"
                                    />
                                    <label htmlFor="profileImageUpload" className="cursor-pointer kg-green">
                                        <i className="bi bi-pencil fs-4" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-6 col-8">
                            <div>
                                <h2 className="fw-bold">{profilData && profilData.name}</h2>
                            </div>
                            <div className="kg-dark-gray">
                                <span>{profilData && profilData.referralCode}</span>
                            </div>
                            <div className="text-nowrap mt-3">
                                <i className="bi bi-envelope-fill kg-warning me-1 me-sm-2" /><span>{profilData && profilData.email}</span>
                            </div>
                            <div className="text-nowrap">
                                <i className="bi bi-telephone-fill kg-green me-1 me-sm-2" /><span>{profilData && profilData.id}</span>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-3 col-4 text-center">
                            <QRCode
                                size={256}
                                className="border p-1"
                                style={{height: "7rem", width: "7rem" }}
                                value={`${API_HOST}/magicLink?referralCode=` + profilData.referralCode}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-6">
                        <Link to={MY_DEALERSHIP}>
                            <div className="text-center">
                                <div className="mx-auto d-inline-flex align-items-center justify-content-center kg-white kg-bg-warning circle text-center align-middle"><i className="bi bi-person-fill fs-2" /></div>
                                <div className="fw-bold mt-2">Dealerships</div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-6">
                        <Link to={MYPOSTINGS}>
                            <div className="text-center">
                                <div className="mx-auto d-inline-flex align-items-center justify-content-center kg-white kg-bg-green circle text-center align-middle"><i className="bi bi-list fs-2" /></div>
                                <div className="fw-bold mt-2">Postings</div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="row gap-3 px-sm-4 px-2">
                    {
                        PROFILE_OPTIONS.map(opt => (
                            <Link key={opt.name} to={opt.link} className="kg-bg-light kg-dark-gray hover-kg-bg-green transition transition-duration-700 hover-kg-white p-3 rounded-4 shadow-sm">
                                <i className={opt.icon} /> <span>{opt.name}</span>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </MainAdsLayout>

    );
}

export default Profile;
