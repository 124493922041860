/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:8fea6398-ceda-4de6-85cf-da0db9161c9c",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_49nxpi6Tu",
    "aws_user_pools_web_client_id": "sp4tjjvuh9odp424a300p8jp3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://b6edv2ucnzcyxjn2jwvx4qifp4.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-bfzka45ydzdydhm4oxbzwt2ili",
    "aws_user_files_s3_bucket": "lookup-system-resources",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "aws_dynamodb_all_tables_region": "ap-south-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "user-dev",
            "region": "ap-south-1"
        }
    ]
};


export default awsmobile;
